import { useEffect, useState } from "react";
import { Formik } from "formik";
import { startCase } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { Icon, Text } from "src/ccl/document";
import { Box, Container, Flex, Grid, LegacyContainer } from "src/ccl/layout";
import { Button, Link } from "src/ccl/navigation";
import { Loading, PopupNotification, StepHeader } from "src/components";
import { SectionHeader } from "src/components/SectionHeader";
import {
  FeaturedAssetForm,
  FieldInfoText,
  ProfileForm,
} from "src/components/dashboards/agents";
import {
  initialUserValues,
  UserValidationSchema,
} from "src/components/dashboards/agents/talentManagement/forms/formConfigV2";
import {
  AssetFileUploadInput,
  AssetKind,
  TalentVertical,
} from "src/graphql/types";
import {
  useFeatures,
  useStoreModel,
  useUserKindContext,
  useVerticalConfigurations,
} from "src/hooks";
import { sendToAmplitude } from "src/utils/analytics";
import { toSentence } from "src/utils/lang";
import { getConfigForVertical, verticalIconMap } from "src/utils/user";
import { getValidationErrors } from "src/validations";

interface ProfileProps {
  vertical: TalentVertical;
}

export const Profile = ({ vertical }: ProfileProps) => {
  const draftTalent = useStoreModel("draftTalent");
  const history = useHistory();

  const [mounted, setMounted] = useState(false);

  const { pathname } = useLocation();
  const currentUser = useStoreModel("currentUser");
  const userKindContext = useUserKindContext(currentUser);
  const agencyName = currentUser.me?.agency?.name;

  const { featureEnabled } = useFeatures();
  const ContainerComponent = featureEnabled("unified_dashboards")
    ? Container
    : LegacyContainer;

  useEffect(() => {
    sendToAmplitude("adds creative profile - lands on page", {
      url: pathname,
      userType: userKindContext,
      agency: agencyName,
    });
  }, []);

  const [showPopupNotification, setShowPopupNotification] = useState(false);

  const {
    loading: verticalConfigLoading,
    error: verticalConfigError,
    configurations: verticalConfigs,
  } = useVerticalConfigurations();

  useEffect(() => {
    setMounted(true);
  }, []);

  if (verticalConfigLoading) {
    return <Loading />;
  }

  const verticalConfig = getConfigForVertical(verticalConfigs || [], vertical);

  if (verticalConfigError || !verticalConfigs || !verticalConfig) {
    return <Text variant="h4">Oops, something went wrong</Text>;
  }

  const initialValues =
    draftTalent.details ||
    initialUserValues(
      verticalConfig,
      featureEnabled("auto_hide_tpa_talent")
        ? currentUser.isFirstPartyAgent
        : true,
    );

  if (draftTalent?.shrineAssets) {
    initialValues.talentProfile.shrineAssets = [
      draftTalent.shrineAssets as AssetFileUploadInput,
    ];
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        const { talentProfile } = values;
        draftTalent.setDetails({
          ...values,
          name: `${values.firstName} ${values.lastName}`,
          talentProfile: {
            ...talentProfile,
            vertical: vertical,
            isContactLaunch: false,
            minDayRate: talentProfile.minDayRate
              ? talentProfile.minDayRate
              : undefined,
            maxDayRate: talentProfile.maxDayRate
              ? talentProfile.maxDayRate
              : undefined,
          },
        });
        history &&
          history.push(
            `/dashboard/agent/talent/new/portfolio?v=${verticalConfig.name}s`,
          );
      }}
      validate={async (values) => {
        const schema = UserValidationSchema(
          verticalConfig,
          values.talentProfile.gender,
          "create",
        );

        return getValidationErrors(values, schema);
      }}
      validateOnBlur={true}
      validateOnChange={false}
      validateOnMount={false}
    >
      {({ values, submitForm, errors, setFieldValue }) => {
        const errorFieldNames = Object.keys(errors).map((errorName) =>
          startCase(errorName).toLowerCase(),
        );
        return (
          <>
            <ContainerComponent css={{ pt: "14px" }} variant="gutter">
              <Link
                to="/dashboard/agent/talent"
                css={{ textDecoration: "none" }}
              >
                <Flex
                  css={{
                    gap: "$4",
                    alignItems: "center",
                  }}
                >
                  <Icon variant="chevronLeft" size={28} />
                  <Text variant="nh3">Add a new talent</Text>
                </Flex>
              </Link>
            </ContainerComponent>

            <StepHeader
              labels={["Talent details", "Social Media", "Payment details"]}
              buttons={[
                {
                  label: "Continue",
                  onClick: () => {
                    sendToAmplitude("adds creative profile - clicks continue", {
                      url: pathname,
                      userType: userKindContext,
                      agency: agencyName,
                    });
                    submitForm();
                    if (errors) {
                      setShowPopupNotification(true);
                      setTimeout(() => {
                        setShowPopupNotification(false);
                      }, 6000);
                    }
                  },
                  variant: "primaryCta",
                },
              ]}
              parentMounted={mounted}
            />

            <ContainerComponent css={{ pt: "47px" }} variant="gutter">
              <Flex css={{ flexDirection: "column" }}>
                {showPopupNotification && (
                  <PopupNotification
                    showClose={true}
                    onClose={() => {
                      setShowPopupNotification(false);
                    }}
                  >
                    <Flex
                      css={{
                        gap: "$4",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Box
                        css={{
                          width: "3px",
                          minHeight: "22px",
                          backgroundColor: "red",
                          borderRadius: "4px",
                        }}
                      />
                      <Text color="black" weight="bold">
                        The {toSentence(errorFieldNames)}{" "}
                        {errorFieldNames.length === 1
                          ? "field needs "
                          : "fields need "}{" "}
                        attention
                      </Text>
                    </Flex>
                  </PopupNotification>
                )}

                <Flex
                  data-step-header-section
                  css={{ flexDirection: "column", gap: "$5" }}
                >
                  <Text variant="h4">What type of talent are you adding?</Text>
                  <Flex css={{ gap: "$10", flexWrap: "wrap" }}>
                    {verticalConfigs.map((config, i) => (
                      <Link
                        key={i}
                        to={`/dashboard/agent/talent/new/profile?v=${config.name}`}
                        css={{
                          cursor:
                            verticalConfig.name === config.name
                              ? "default"
                              : "pointer",
                          textDecoration: "none",
                        }}
                      >
                        <Flex
                          css={{
                            flexDirection: "column",
                            gap: "$4",
                            alignItems: "center",
                          }}
                        >
                          <Icon
                            variant={
                              verticalIconMap[config.id as TalentVertical]
                            }
                            size={30}
                            color={
                              verticalConfig.name === config.name
                                ? "black"
                                : "grey5"
                            }
                          />
                          <Text
                            variant={
                              verticalConfig.name === config.name
                                ? "b3Bold"
                                : "b3"
                            }
                            color={
                              verticalConfig.name === config.name
                                ? "black"
                                : "grey5"
                            }
                          >
                            {config.label}
                          </Text>
                          {verticalConfig.name === config.name && (
                            <Box
                              css={{
                                width: "27px",
                                height: "2px",
                                background: "$black",
                              }}
                            ></Box>
                          )}
                        </Flex>
                      </Link>
                    ))}
                  </Flex>
                </Flex>

                <Box css={{ mt: "42px" }}>
                  <SectionHeader step="1" label="Talent details" />

                  <Text variant="b2Bold">Set profile image</Text>

                  <Box css={{ width: "336px" }}>
                    <FieldInfoText text="This is the main image that will appear on our marketplace. Make sure it’s cropped to your liking." />
                  </Box>

                  <FeaturedAssetForm
                    onUpload={([uploadedFile]) => {
                      if (!uploadedFile?.mediaUrl) {
                        return;
                      }
                      draftTalent.setFeaturedAsset({
                        kind: AssetKind.Featured,
                        mediaUrl: uploadedFile.mediaUrl,
                      });
                      delete uploadedFile.mediaUrl;
                      draftTalent.setShrineAssets({
                        kind: AssetKind.Featured,
                        file: uploadedFile,
                      });
                      setFieldValue("talentProfile.shrineAssets", [
                        {
                          kind: "FEATURED",
                          file: uploadedFile,
                        },
                      ]);
                    }}
                    onDelete={() => {
                      draftTalent.setFeaturedAsset(null);
                      setFieldValue("talentProfile.shrineAssets", []);
                    }}
                    featuredAsset={draftTalent.featuredAsset || undefined}
                    error={errors.talentProfile?.shrineAssets}
                  />
                </Box>

                <Grid
                  css={{
                    gridGap: "$9",
                    gridColumns: 1,
                    alignItems: "flex-start",
                    "@bp2": {
                      gridColumns: "2fr 1fr",
                    },
                  }}
                >
                  <ProfileForm
                    values={values}
                    vertical={vertical}
                    verticalConfig={verticalConfig}
                    context="create"
                  />
                </Grid>

                <Box
                  css={{
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    zIndex: "$400",
                    width: "100%",
                    height: "96px",
                    background: "$white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "20px",
                    boxShadow: "0px 4px 20px 0px #00000014",
                    "@sm": {
                      display: "none",
                    },
                  }}
                >
                  <Button
                    data-test-id="CAJContinueButton"
                    variant="primaryCta"
                    onClick={() => {
                      sendToAmplitude(
                        "adds creative profile - clicks continue",
                        {
                          url: pathname,
                          userType: userKindContext,
                          agency: agencyName,
                        },
                      );
                      submitForm();
                      if (errors) {
                        setShowPopupNotification(true);
                        setTimeout(() => {
                          setShowPopupNotification(false);
                        }, 6000);
                      }
                    }}
                    css={{ width: "100%" }}
                  >
                    Continue
                  </Button>
                </Box>
              </Flex>
            </ContainerComponent>
          </>
        );
      }}
    </Formik>
  );
};
