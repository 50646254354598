import { CSS, ComponentProps } from "@stitches/react";
import { TwicImg } from "@twicpics/components/react";
import { useContext } from "react";
import { SelectBadge } from "src/ccl/blocks/badge/SelectBadge";
import { Text } from "src/ccl/document";
import { mergeCss, styled } from "src/ccl/stitches";
import { tokens } from "src/ccl/stitches/theme";
import { MediaProvider, TwicpicsContext } from "src/contexts/Twicpics";

const Wrapper = styled("div", {
  borderRadius: "$round",
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "$black",
});

const Image = styled("img", {
  display: "block",
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

export interface AvatarProps extends ComponentProps<typeof Wrapper> {
  initials?: string;
  imageUrl?: string;
  size?: `$${keyof typeof tokens.sizes}`;
  fontVariant?: string;
  fontColor?: keyof typeof tokens.colors;
  testId?: string;
  css?: CSS;
  isContactSelect?: boolean;
}

export const Avatar = ({
  initials,
  imageUrl,
  size = "$avatar",
  css = {},
  fontVariant = "mini",
  testId,
  fontColor = "white",
  isContactSelect = false,
}: AvatarProps) => {
  const isTwicpicsEnabled =
    useContext(TwicpicsContext).mediaProvider === MediaProvider.TWICPICS;

  if (isTwicpicsEnabled && imageUrl) {
    imageUrl = new URL(imageUrl).pathname;
  }

  return (
    <>
      {isContactSelect && <SelectBadge variant="avatar" />}
      <Wrapper
        data-test-id={testId}
        css={mergeCss(
          { width: size, height: size, position: "relative", zIndex: 2 },
          css,
        )}
      >
        {imageUrl ? (
          isTwicpicsEnabled ? (
            <TwicImg src={imageUrl} style={{ width: "100%" }} focus="faces" />
          ) : (
            <Image src={imageUrl} aria-hidden="true" />
          )
        ) : (
          <Text color={fontColor} variant={fontVariant}>
            {initials}
          </Text>
        )}
      </Wrapper>
    </>
  );
};
