import type * as Stitches from "@stitches/react";
import {
  AgentTalentCard,
  Icon,
  MultiImageTalentCard,
  TalentCard,
  TalentCardProps,
  Text,
} from "src/ccl/document";
import {
  TalentProfile,
  User,
  VerticalConfigurationCard,
} from "src/graphql/generated/types";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Loading } from "src/components";
import { mergeCss } from "src/ccl/stitches";
import { useIsMobile } from "src/hooks";
import { breakpoints } from "src/ccl";

export type TalentCardGridContext =
  | "agentDashboard"
  | "bookmarks"
  | "default"
  | "agencyHomepage";

const calculateColumnCount = (
  context: TalentCardGridContext,
  cardType: VerticalConfigurationCard,
  displayListView?: boolean,
) => {
  if (context === "bookmarks") {
    const values = {
      "@initial": 1,
      "@sm": 1,
      "@md": 1,
      "@lg": 1,
      "@xl": 2,
      "@bp5": 2,
    };
    return {
      gridColumns: values,
    };
  } else if (context === "agentDashboard") {
    if (cardType === VerticalConfigurationCard.Single) {
      const values = {
        "@initial": 1,
        "@sm": 2,
        "@md": 2,
        "@lg": 3,
        "@xl": 3,
        "@bp5": 3,
      };
      return {
        gridColumns: values,
      };
    } else {
      const values = {
        "@initial": 1,
        "@sm": 2,
        "@md": 3,
        "@lg": 3,
        "@xl": 3,
        "@bp5": 3,
      };
      return {
        gridColumns: values,
      };
    }
  }
  {
    if (displayListView) {
      const values = {
        "@initial": 1,
        "@sm": 1,
        "@md": 1,
        "@lg": 1,
        "@xl": 1,
        "@bp5": 1,
      };
      return {
        gridColumns: values,
      };
    } else if (
      cardType === VerticalConfigurationCard.Single &&
      !displayListView
    ) {
      const values = {
        "@initial": 1,
        "@sm": 2,
        "@md": 2,
        "@lg": 3,
        "@xl": 4,
        "@bp5": 4,
      };
      return {
        gridColumns: values,
      };
    } else {
      return {
        gridColumns: {
          "@initial": "repeat(1, minmax(100%, 1fr))",
          "@sm": "repeat(1, minmax(100%, 1fr))",
          "@md": "repeat(1, minmax(100%, 1fr))",
          "@lg": "repeat(1, minmax(100%, 1fr))",
          "@xl": "repeat(1, minmax(100%, 1fr))",
          "@bp5": "repeat(1, minmax(100%, 1fr))",
        },
      };
    }
  }
};

const calculateGaps = (
  context: TalentCardGridContext,
  cardType: VerticalConfigurationCard,
) => {
  if (context === "agentDashboard") {
    if (cardType === VerticalConfigurationCard.Single) {
      const values = { "@initial": "$9", "@md": "25px", "@bp5": "33px" };
      return {
        rows: values,
        columns: values,
      };
    } else {
      const values = { "@initial": "$9", "@md": "$9", "@bp5": "$9" };
      return {
        rows: values,
        columns: values,
      };
    }
  } else {
    const values = { "@initial": "$9", "@md": "25px", "@bp5": "33px" };
    if (cardType === VerticalConfigurationCard.Single) {
      return {
        rows: values,
        columns: values,
      };
    } else {
      return {
        rows: { "@initial": "$9", "@md": "$9", "@bp5": "$9" },
        columns: { "@initial": "$0", "@md": "$0", "@bp5": "$0" },
      };
    }
  }
};

export interface TalentCardGridProps extends Omit<TalentCardProps, "talent"> {
  talent: User[];
  loading?: boolean;
  cardType?: VerticalConfigurationCard;
  context?: TalentCardGridContext;
  containerCss?: Stitches.CSS;
  openTalentProfileModal?: (slug: string) => void;
  packageAmplitudeProps?: {
    packageName: string;
    agencyName?: string;
    agencyId?: string;
  };
  showListView?: boolean;
  type?: "select" | "open";
}

export const TalentCardGrid = ({
  talent,
  loading,
  url,
  context = "default",
  cardType,
  containerCss = {},
  openTalentProfileModal,
  packageAmplitudeProps,
  showListView,
  type,
  ...cardProps
}: TalentCardGridProps) => {
  const isMobile = useIsMobile(breakpoints.xl);
  const displayListView =
    !isMobile &&
    showListView &&
    (location.pathname.includes("/jobs/new/shortlist") ||
      location.pathname.includes("/agency"));

  const columns = calculateColumnCount(
    context,
    cardType || VerticalConfigurationCard.Single,
    displayListView,
  );

  const gridGaps = calculateGaps(
    context,
    cardType || VerticalConfigurationCard.Single,
  );

  if (loading) {
    return <Loading />;
  }

  if (talent.length === 0) {
    return (
      <Flex
        css={{
          flexDirection: "column",
          justifyContent: "center",
          gap: "18px",
          alignItems: "center",
          "@md": { position: "relative", top: "$16" },
        }}
      >
        <Icon variant="casting" size={60} color="grey3" />
        <Flex
          css={{
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <Text variant="nh4" color="grey5">
            We&apos;re working on adding talent
          </Text>
          <Text variant="b2" color="grey5">
            Check back soon to browse our roster
          </Text>
        </Flex>
      </Flex>
    );
  }

  return (
    <Box
      data-test-id="TalentCardGrid"
      css={mergeCss(
        {
          px: "$5",
          my: "$5",
          "@xl": {
            px: 0,
            my: 0,
          },
        },
        containerCss,
      )}
    >
      <Box
        css={{
          overflow: "visible",
        }}
      >
        <Grid
          css={{
            gridColumns: columns.gridColumns["@initial"],
            rowGap: gridGaps.rows["@initial"],
            columnGap: gridGaps.columns["@initial"],
            "@sm": {
              gridColumns: columns.gridColumns["@sm"],
            },
            "@md": {
              gridColumns: columns.gridColumns["@md"],
              rowGap: gridGaps.rows["@md"],
              columnGap: gridGaps.columns["@md"],
            },
            "@lg": {
              gridColumns: columns.gridColumns["@lg"],
            },
            "@xl": {
              gridColumns: columns.gridColumns["@xl"],
            },
            "@bp5": {
              gridColumns: columns.gridColumns["@bp5"],
              rowGap: gridGaps.rows["@bp5"],
              columnGap: gridGaps.columns["@bp5"],
            },
          }}
        >
          {talent.map((t) => {
            if (context === "agentDashboard" && cardType) {
              return (
                <AgentTalentCard
                  variant={
                    cardType === VerticalConfigurationCard.Single
                      ? "single"
                      : "multi"
                  }
                  talent={t}
                  isContactSelect={
                    type === "select" ||
                    (t.profile as TalentProfile)?.isContactSelect
                  }
                  key={`agent-talent-card-${t.id}`}
                />
              );
            }

            return cardType === VerticalConfigurationCard.Multiple ||
              displayListView ? (
              <MultiImageTalentCard
                talent={t}
                key={`multi-image-talent-card-${t.id}`}
                shortlistToggle={(talent, checked) => {
                  cardProps.onCheck && cardProps.onCheck(talent, checked);
                }}
                isShortlisted={(t) => {
                  return !!cardProps.isChecked && cardProps.isChecked(t);
                }}
                url={url}
                inListView={displayListView}
                openTalentProfileModal={openTalentProfileModal}
                analyticsProperty={cardProps.analyticsProperty}
                {...cardProps}
              />
            ) : (
              <TalentCard
                talent={t}
                key={`talent-card-${t.id}`}
                url={url}
                context={context}
                openTalentProfileModal={openTalentProfileModal}
                packageAmplitudeProps={packageAmplitudeProps}
                shortlistToggle={(talent: User, checked: boolean) => {
                  cardProps.onCheck && cardProps.onCheck(talent, checked);
                }}
                analyticsEventPage={cardProps.analyticsEventPage}
                analyticsProperty={cardProps.analyticsProperty}
                isShortlisted={(t: User) => {
                  return !!cardProps.isChecked && cardProps.isChecked(t);
                }}
                {...cardProps}
              />
            );
          })}{" "}
        </Grid>
      </Box>
    </Box>
  );
};
