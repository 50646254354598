import React, { useContext, useState } from "react";
import type * as Stitches from "@stitches/react";

import { AssetImagePlaceholder } from "./AssetImagePlaceholder";
import { AssetImageSize } from "./types";
import { isSingleSizeValue } from "./helpers";
import { TwicPicture } from "src/ccl/document/twicPicture";

import { breakpoints, ResponsiveValue, mergeCss } from "src/ccl/stitches";
import { Img } from "src/ccl/document/img";
import { Scalars } from "src/graphql/types";
import { urlAddQuery } from "src/utils/url";
import { MediaProvider, TwicpicsContext } from "src/contexts/Twicpics";

export type AssetImageType = {
  id?: Scalars["ID"];
  mediaUrl: string;
  width?: number;
  height?: number;
};

export type CropType = "faces" | "clip";

const buildUrl = (
  asset: AssetImageType,
  size: AssetImageSize,
  dpr: number,
  crop: CropType,
) => {
  // DPR is auto-calculated by TwicPicture component
  let query = `auto=format&fit=${size.fit || "crop"}&dpr=${dpr}&crop=${crop}`;

  if (size.aspectRatio && (!size.width || !size.height)) {
    query += `&ar=1:${size.aspectRatio}`;
  }

  if (size.width) {
    query += `&w=${size.width}`;
  }

  if (size.height) {
    query += `&h=${size.height}`;
  }

  if (size.fit === "fill") {
    query += `&fill=blur`;
  }

  return urlAddQuery(asset.mediaUrl, query);
};

const previouslyLoadedImages: string[] = [];

export interface AssetImageProps {
  asset: AssetImageType;
  size: ResponsiveValue<AssetImageSize>;
  alt: string;
  eager?: boolean;
  imgCss?: Stitches.CSS;
  containerCss?: Stitches.CSS;
  crop?: CropType;
}

export const AssetImage = React.forwardRef<HTMLPictureElement, AssetImageProps>(
  (
    {
      asset,
      size, // I tested not passing size in Twicpics and it looked fine and responsive
      alt,
      imgCss = {},
      eager = false,
      containerCss,
      crop = "faces", // focus=faces in Twicpics
    },
    ref,
  ) => {
    const [loaded, setLoaded] = useState(
      previouslyLoadedImages.includes(asset.mediaUrl),
    );

    const { "@initial": initial, ...sourceBreakpoints } = isSingleSizeValue(
      size,
    )
      ? { "@initial": size }
      : size;

    if (!initial) {
      throw new Error("An initial size must be defined");
    }

    const onLoad = () => {
      previouslyLoadedImages.push(asset.mediaUrl);
      setLoaded(true);
    };

    const isTwicpicsEnabled =
      useContext(TwicpicsContext).mediaProvider === MediaProvider.TWICPICS;

    const path = new URL(asset.mediaUrl).pathname;
    const initial1x = buildUrl(asset, initial, 1, crop);
    const initial2x = buildUrl(asset, initial, 2, crop);

    const initialImg = () => {
      return isTwicpicsEnabled ? (
        <TwicPicture
          src={path}
          alt={alt}
          focus="faces"
          eager={eager}
          css={imgCss}
          {...(initial?.aspectRatio ? { ratio: 1 / initial!.aspectRatio } : {})}
        />
      ) : (
        <Img
          src={initial1x}
          srcSet={`${initial1x}, ${initial2x} 2x`}
          alt={alt}
          onLoad={onLoad}
          onError={onLoad}
          loading={eager ? "eager" : "lazy"}
          css={mergeCss(
            {
              transition: "0.1s opacity ease",
              opacity: loaded ? 1 : 0,
            },
            imgCss,
          )}
        />
      );
    };

    const sources = Object.entries(sourceBreakpoints)
      .sort(([breakpointA], [breakpointB]) => {
        const keyA = breakpointA.replace("@", "");
        const keyB = breakpointB.replace("@", "");

        const widthA = breakpoints[keyA as keyof typeof breakpoints];
        const widthB = breakpoints[keyB as keyof typeof breakpoints];
        return widthB - widthA;
      })
      .map(([breakpoint, size]) => {
        const url1x = buildUrl(asset, size, 1, crop);
        const url2x = buildUrl(asset, size, 2, crop);
        const minWidth =
          breakpoints[breakpoint.replace("@", "") as keyof typeof breakpoints];

        return (
          <source
            key={breakpoint}
            media={`(min-width: ${minWidth}px)`}
            srcSet={`${url1x}, ${url2x} 2x`}
          />
        );
      });

    const containerProps = {
      asset,
      size: { "@initial": initial, ...sourceBreakpoints },
      css: containerCss,
    };

    return (
      <AssetImagePlaceholder {...containerProps}>
        {sources.length > 0 ? (
          isTwicpicsEnabled ? (
            <TwicPicture src={path} alt={alt} focus="faces" css={imgCss} />
          ) : (
            <picture ref={ref}>
              {sources}
              {initialImg()}
            </picture>
          )
        ) : (
          initialImg()
        )}
      </AssetImagePlaceholder>
    );
  },
);
