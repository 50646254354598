import { Box } from "src/ccl/layout";
import ContactSelectBadge from "src/assets/icons/social/contactSelect.png";

interface SelectBadgeProps {
  variant?: "avatar" | "card";
}

export const SelectBadge = ({ variant = "avatar" }: SelectBadgeProps) => {
  const styles = {
    avatar: {
      badge: {
        position: "absolute",
        top: -20,
        right: -20,
        width: 40,
        height: 40,
        borderRadius: "$round",
        background: "linear-gradient(180deg, #CCA850 0%, #F6E68F 100%)",
        zIndex: 3,
        border: "1.5px solid $white",
        boxShadow: "-1.8px 1.8px 9px 0px #8C67144D",
      },
      image: {
        position: "absolute" as const,
        top: "-13px",
        right: "-14px",
        width: "24px",
        height: "24px",
        zIndex: 4,
      },
    },
    card: {
      badge: {
        position: "absolute",
        top: -60,
        right: -60,
        width: 120,
        height: 120,
        border: "unset",
        background: "linear-gradient(180deg, #CCA850 0%, #F6E68F 100%)",
        zIndex: 1,
        transform: "rotate(45deg)",
      },
      image: {
        position: "absolute" as const,
        top: "7px",
        right: "5px",
        width: "32px",
        height: "32px",
        zIndex: 2,
      },
    },
  };

  return (
    <>
      <Box css={styles[variant].badge} />
      <img
        src={ContactSelectBadge}
        alt="Contact Select"
        style={styles[variant].image}
      />
    </>
  );
};
