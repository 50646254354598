import {
  AgentTalentCardFeature,
  AgentTalentCardMeta,
  AgentTalentCardVariant,
} from "src/ccl/document";
import { Box } from "src/ccl/layout";
import { User } from "src/graphql/generated/types";
import { SelectBadge } from "src/ccl/blocks/badge/SelectBadge";

interface AgentTalentCardProps {
  variant?: AgentTalentCardVariant;
  talent: User;
  isContactSelect?: boolean;
}
export const AgentTalentCard = ({
  variant = "single",
  talent,
  isContactSelect = false,
}: AgentTalentCardProps) => (
  <Box css={{ position: "relative", overflow: "hidden", borderRadius: 13 }}>
    {isContactSelect && <SelectBadge variant="card" />}
    <AgentTalentCardFeature variant={variant} talent={talent} />
    <Box css={{ mt: "$4" }}>
      <AgentTalentCardMeta talent={talent} />
    </Box>
  </Box>
);
