import { gql } from "@apollo/client";

export const NOTIFICATION_SUBSCRIPTION = gql`
  subscription OnNotificationAdded {
    notificationAdded {
      type
      message {
        id
        sender {
          id
          name
          featuredAsset {
            mediaUrl
          }
        }
        content
        messageAssets {
          id
          mediaUrl
        }
        createdAt
        chatroom {
          id
          name
        }
      }
    }
  }
`;
