import { useRouteMatch } from "react-router-dom";

import { FC } from "react";
import { Container, TwoPane } from "src/ccl/layout";
import { Page } from "src/ccl/templates/Page";
import { SideNavBar, DashboardHeader } from "src/ccl/blocks";
import { TwicpicsProvider } from "src/contexts/Twicpics";

export const DashboardPage = ({
  children,
  hideSidePane,
  FooterComponent,
}: {
  children: React.ReactNode;
  hideSidePane?: boolean;
  FooterComponent?: FC | undefined;
}) => {
  const { path } = useRouteMatch();
  return (
    <Page
      header={
        <TwicpicsProvider>
          <DashboardHeader />
        </TwicpicsProvider>
      }
      FooterComponent={FooterComponent}
    >
      <Container>
        <TwoPane
          primaryPane={<SideNavBar path={path} />}
          primaryPaneCss={{
            display: "none",
            position: "sticky",
            "@md": { display: "initial" },
          }}
          hideSidePane={hideSidePane}
        >
          {children}
        </TwoPane>
      </Container>
    </Page>
  );
};
