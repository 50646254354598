import { useDraggable } from "@dnd-kit/core";
import { VariantProps, keyframes } from "@stitches/react";
import { capitalize } from "lodash";
import React, { DragEventHandler, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Avatar } from "src/ccl/blocks";
import { BookmarkCircle, Checkbox } from "src/ccl/data-entry";
import {
  AssetImage,
  AvailableIcon,
  Icon,
  ImageGallery,
  InfoBanner,
  Review,
  Text,
} from "src/ccl/document";
import { TalentCardGridContext } from "src/ccl/document/talentCardGrid";
import { Tooltip } from "src/ccl/feedback";
import { Box, Flex } from "src/ccl/layout";
import { ExternalLink, Link } from "src/ccl/navigation";
import { Button } from "src/ccl/navigation/button";
import { styled } from "src/ccl/stitches/config";
import { ShortlistButton, StyledShortlistButton } from "src/components";
import { BookmarkAndCollectionCircle } from "src/components/dashboards/booker/bookmarks/collections";
import { twicpicsUrl } from "src/config";
import { MediaProvider, TwicpicsContext } from "src/contexts/Twicpics";
import {
  SocialMedia,
  SocialMediaPlatform,
  TalentProfile,
  TalentSearchLocationContext,
  TalentVertical,
  User,
} from "src/graphql/types";
import { useStoreModel, useUserKindContext } from "src/hooks";
import { sendToAmplitude } from "src/utils/analytics";
import { addCommas, getSocialMedia } from "src/utils/socialMedia";
import { urlAddQuery } from "src/utils/url";
import {
  getFullTravelLocation,
  niceResponseTimeText,
  verticalIconMap,
  verticalIconMapAlt,
  verticalMap,
} from "src/utils/user";

const UpdatedCardLocationsRegex = [
  new RegExp("/jobs/new/shortlist|/dashboard/client/(bookmarks|collections)"),
  new RegExp("/agency/(.+)"),
  new RegExp("/packages/(.+)"),
  new RegExp("/dashboard/client/talent/marketplace"),
  new RegExp("/dashboard/client/talent/select"),
  new RegExp("/dashboard/client/talent/open"),
  new RegExp("/dashboard/client/bookmark-collections"),
  new RegExp("/dashboard/agent/packages"),
  new RegExp("/dashboard/client/jobs/(.+)/edit/shortlist"),
];

const MAX_GALLERY_ITEMS = 6;

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  variants: {
    opacity: {
      dimmed: {
        opacity: 0.5,
      },
      full: {
        opacity: 1,
      },
    },
  },
});

const ImageContainer = ({
  hiddenProfile,
  children,
}: {
  hiddenProfile?: boolean;
  children: React.ReactNode;
}) => (
  <Box
    css={{
      position: "relative",
    }}
  >
    {hiddenProfile && (
      <Flex
        data-test-id="HiddenProfileOverlay"
        css={{
          backgroundColor: "$white",
          opacity: 0.7,
          position: "absolute",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "$100",
          pointerEvents: "none",
          flexDirection: "column",
        }}
      >
        <Icon variant="hiddenProfile" size={40} />
      </Flex>
    )}
    {children}
  </Box>
);

const BackgroundImage = styled("div", {
  backgroundSize: "cover",
  position: "absolute",
  top: 0,
  height: "100%",
  width: "100%",
  opacity: 0,
});

const onBackgroundImageHover = (urls: string[], isTwicpicsEnabled: boolean) => {
  const getBackgroundSrc = (url: string) => {
    const imgixArgs = "auto=format&fit=crop&dpr=1&crop=faces&w=324&h=422";
    let backgroundSrc = urlAddQuery(url, imgixArgs);
    const twicpicsArgs = "?twic=v1/focus=smart/cover=324x422";
    if (isTwicpicsEnabled) {
      backgroundSrc = `${url}${twicpicsArgs}`;
    }
    return backgroundSrc;
  };

  const fifthIndex = urls.length > 4 ? 4 : 2;
  return keyframes({
    "0%": { opacity: 0 },
    "5%": {
      background: `url(${getBackgroundSrc(urls[1])}) center`,
      opacity: 1,
      backgroundSize: "cover",
    },
    "20%": {
      background: `url(${getBackgroundSrc(urls[1])}) center`,
      opacity: 1,
      backgroundSize: "cover",
    },
    "25%": {
      background: `url(${getBackgroundSrc(urls[2])}) center`,
      backgroundSize: "cover",
      opacity: 1,
    },
    "40%": {
      background: `url(${getBackgroundSrc(urls[2])}) center`,
      backgroundSize: "cover",
      opacity: 1,
    },
    "45%": {
      background: `url(${getBackgroundSrc(urls[3])}) center`,
      backgroundSize: "cover",
      opacity: 1,
    },
    "60%": {
      background: `url(${getBackgroundSrc(urls[3])}) center`,
      backgroundSize: "cover",
      opacity: 1,
    },
    "65%": {
      background: `url(${getBackgroundSrc(urls[fifthIndex])}) center`,
      backgroundSize: "cover",
      opacity: 1,
    },
    "80%": {
      background: `url(${getBackgroundSrc(urls[fifthIndex])}) center`,
      backgroundSize: "cover",
      opacity: 1,
    },
    "85%": {
      background: `url(${getBackgroundSrc(urls[0])}) center`,
      backgroundSize: "cover",
      opacity: 1,
    },
    "100%": {
      background: `url(${getBackgroundSrc(urls[0])}) center`,
      backgroundSize: "cover",
      opacity: 1,
    },
  });
};

const CheckboxContainer = styled("div", {
  position: "absolute",
  bottom: 10,
  right: 10,
  zIndex: "$100",
});

const Meta = styled("div", {
  width: "100%",

  mt: "$3",
  variants: {
    variant: {
      condensed: {
        ml: "$5",
        alignItems: "center",
      },
    },
  },
});

const SubHeading = styled("div", {
  mt: "$2",
});

const TravelLocationsContainer = styled(Flex, {
  backgroundColor: "$black",
  width: "28px",
  height: "28px",
  borderRadius: "50%",
  position: "absolute",
  top: 10,
  right: 10,
  zIndex: "$200",
  justifyContent: "center",
  alignItems: "center",
});

const ShortlistButtonContainer = styled("div", {
  position: "absolute",
  right: 18,
  zIndex: "$100",
  bottom: 34,
  "@bp3": {
    bottom: 18,
  },
});
export interface TalentCardProps extends VariantProps<typeof Container> {
  showHiddenProfileOverlay?: boolean;
  talent: User;
  openInModal?: boolean;
  getSubHeading?: (talent: User) => React.ReactNode;
  isRemovable?: (talent: User) => boolean;
  onRemove?: (talent: User) => void;
  isCheckable?: (talent: User) => boolean;
  isChecked?: (talent: User) => boolean;
  onCheck?: (talent: User, checked: boolean) => void;
  shortlistToggle?: (talent: User, isShorlisted: boolean) => void;
  isShortlisted?: (talent: User) => boolean;
  isDisabled?: boolean;
  url?: (slug: string) => string;
  showRequiresAttentionIcon?: boolean;
  linksToProfile?: (talent: User) => boolean;
  travelLocation?: string;
  locationContext?: TalentSearchLocationContext;
  context?: TalentCardGridContext;
  handleBookmark?: (isChecked: boolean, talentId: string) => void;
  isBookmarkDisabled?: boolean;
  onClick?: () => void;
  openTalentProfileModal?: (slug: string) => void;
  packageAmplitudeProps?: {
    packageName: string;
    agencyName?: string;
    agencyId?: string;
  };
  externalLink?: boolean;
  onAddToCollection?: (talent: User) => void;
  bookmarkCta?: string;
  analyticsEventPage?: string;
  analyticsProperty?: string;
}

export const TalentCard = ({
  talent,
  isDisabled = false,
  isBookmarkDisabled,
  handleBookmark,
  openTalentProfileModal,
  packageAmplitudeProps,
  getSubHeading,
  isRemovable = () => false,
  onRemove = () => {
    throw new Error(
      "onRemove not implemented but isRemovable evaluates to true",
    );
  },
  isCheckable = () => false,
  isChecked = () => {
    throw new Error(
      "isChecked not implemented but isCheckable evaluates to true",
    );
  },
  onCheck = () => {
    throw new Error(
      "onCheck not implemented but isCheckable evaluates to true",
    );
  },
  url = (slug) => `/creatives/${slug}`,
  locationContext,
  travelLocation,
  onClick = () => {},
  isShortlisted,
  shortlistToggle,
  externalLink = false,
  onAddToCollection,
  analyticsEventPage,
  bookmarkCta,
  showHiddenProfileOverlay,
  analyticsProperty,
  ...props
}: TalentCardProps) => {
  const isTwicpicsEnabled =
    useContext(TwicpicsContext).mediaProvider === MediaProvider.TWICPICS;

  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: talent.id,
  });

  const updatedTalentCard: boolean = UpdatedCardLocationsRegex.some((regex) =>
    regex.test(location.pathname),
  );
  const profile = talent.profile as TalentProfile;

  const isFeatured =
    props?.context === "agencyHomepage" && analyticsProperty !== "1"
      ? profile?.isAgencyFeatured
      : profile?.isFeatured;
  const { averageRating, totalReviews, lastPortfolioUploads, portfolioImages } =
    profile;
  const isModel =
    (talent.profile as TalentProfile).vertical === TalentVertical.FashionModel;
  const featuredImage = isModel
    ? talent.featuredAsset
    : (talent.profile as TalentProfile).lastPortfolioUpload;

  let imageGalleryUrls: string[] = [];

  if (featuredImage) {
    imageGalleryUrls.push(featuredImage.mediaUrl);
  }

  if (lastPortfolioUploads) {
    lastPortfolioUploads.map((item) => {
      if (imageGalleryUrls.length < MAX_GALLERY_ITEMS) {
        imageGalleryUrls.push(item.mediaUrl);
      }
    });
  } else {
    (portfolioImages || []).forEach((image) => {
      if (imageGalleryUrls.length < MAX_GALLERY_ITEMS) {
        imageGalleryUrls.push(image.mediaUrl);
      }
    });
  }

  // Remove any duplicates in the imageGalleryUrls array
  imageGalleryUrls = Array.from(new Set(imageGalleryUrls));
  if (isTwicpicsEnabled) {
    imageGalleryUrls = imageGalleryUrls.map((url) => {
      return url.replace(/imgix\.net/g, "twic.pics");
    });
  }
  const fullTravelLocation =
    travelLocation &&
    getFullTravelLocation(travelLocation, profile.additionalLocations);

  const displayTravelIcon =
    fullTravelLocation &&
    fullTravelLocation !== profile.primaryLocation.name &&
    locationContext !== "PRIMARY_ONLY";

  const instagram = getSocialMedia(profile, SocialMediaPlatform.Instagram);
  const tiktok = getSocialMedia(profile, SocialMediaPlatform.Tiktok);
  const youtube = getSocialMedia(profile, SocialMediaPlatform.Youtube);

  const ProfileLink = ({ children }: { children: React.ReactNode }) => {
    return (
      <>
        {!openTalentProfileModal && externalLink ? (
          <ExternalLink
            to={url(talent.slug)}
            css={{
              textDecoration: "none",
            }}
          >
            {children}
          </ExternalLink>
        ) : (
          <Link
            to={url(talent.slug)}
            onClick={(e) => {
              if (openTalentProfileModal) {
                e.preventDefault();
                openTalentProfileModal(talent.slug);
              }

              onClick();
            }}
            css={{
              textDecoration: "none",
            }}
          >
            {children}
          </Link>
        )}
      </>
    );
  };

  const SocialMediaIcon = ({
    platform,
  }: {
    platform: "instagram" | "youtube" | "tiktok";
  }) => {
    const socMedMap = {
      tiktok,
      instagram,
      youtube,
    } as Record<string, SocialMedia>;

    return (
      <Tooltip
        content={`${addCommas(
          socMedMap[platform]?.followerCount || 0,
        )} followers`}
        css={{ zIndex: "250" }}
        hover="noMobileHover"
        alignment="left"
      >
        <Flex
          className="socialMedia"
          css={{
            opacity: 0,
            transition: "opacity 0.25s",
            background: "radial-gradient(circle, black 50%, transparent 50%)",
            borderRadius: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
          data-test-id={`TalentCardSocialMediaIcon-${platform}`}
        >
          <Icon
            variant={platform}
            size={platform === "instagram" ? 32 : 28}
            color="white"
          />
        </Flex>
      </Tooltip>
    );
  };

  const { pathname } = useLocation();
  const currentUser = useStoreModel("currentUser");
  const userKindContext = useUserKindContext(currentUser);
  const responseTime = talent.averageResponseTimeMinutes;
  const displayResponseTime =
    updatedTalentCard && responseTime && responseTime < 1440;

  const shortlisted = !!isShortlisted && isShortlisted(talent);
  const toggleShortlist = () =>
    shortlistToggle && shortlistToggle(talent, !shortlisted);
  const isHiddenProfile =
    profile.published === false && showHiddenProfileOverlay;

  return (
    <Container
      onDragStart={listeners?.onMouseDown as DragEventHandler<HTMLDivElement>}
      {...attributes}
      ref={setNodeRef}
      css={{
        opacity: isDragging ? 0.5 : undefined,
        cursor: "pointer",
        maxWidth: "90vw",
        mx: updatedTalentCard ? "auto" : undefined,
        width: updatedTalentCard ? "100%" : undefined,
        "@bp2": { maxWidth: updatedTalentCard ? "480px" : "320px" },
        "@bp3": {
          [`&:hover ${BookmarkCircle}, &:hover .socialMedia`]: {
            opacity: 0.8,
          },
          ...(!isHiddenProfile && {
            [`&:hover ${StyledShortlistButton}`]: {
              opacity: 1,
            },
          }),
          [`&:hover ${BackgroundImage}`]: {
            animation: `${onBackgroundImageHover(
              imageGalleryUrls,
              isTwicpicsEnabled,
            )} ${imageGalleryUrls.length * 2}s linear infinite`,
            animationDelay: "0.5s",
          },
          mx: undefined,
          maxWidth: "320px",
        },
      }}
      opacity={isDisabled ? "dimmed" : "full"}
      data-test-id="TalentCard"
      {...props}
    >
      <ImageContainer hiddenProfile={isHiddenProfile}>
        {!isModel && (
          <Avatar
            size="$12"
            imageUrl={talent.featuredAsset?.mediaUrl}
            css={{
              border: "2px solid $black",
              position: "absolute",
              left: 10,
              bottom: 10,
              zIndex: "$200",
            }}
          />
        )}
        {displayTravelIcon && (
          <TravelLocationsContainer>
            <Tooltip
              content={`Willing to travel to ${capitalize(fullTravelLocation)}`}
              alignment="left"
            >
              <Flex css={{ justifyContent: "center", alignItems: "center" }}>
                <Icon variant="travelLocation" size={16} color="white" />
              </Flex>
            </Tooltip>
          </TravelLocationsContainer>
        )}
        {isCheckable(talent) && !updatedTalentCard && (
          <CheckboxContainer>
            <Checkbox
              checked={isChecked(talent)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (isDisabled) return;
                const { checked } = e.target;
                onCheck(talent, checked);
                checked && packageAmplitudeProps
                  ? sendToAmplitude("shortlist - selects creative", {
                      ...packageAmplitudeProps,
                      url: pathname,
                      userType: userKindContext,
                    })
                  : checked
                  ? sendToAmplitude("shortlist - selects creative", {
                      url: pathname,
                      userType: userKindContext,
                    })
                  : undefined;
              }}
              data-test-id="TalentCardCheckbox"
            />
          </CheckboxContainer>
        )}
        {updatedTalentCard && (
          <ShortlistButtonContainer data-test-id="TalentCardShortlistButton">
            <ShortlistButton
              shortlisted={shortlisted}
              toggleShortlist={toggleShortlist}
              analyticsEventPage={analyticsEventPage}
            />
          </ShortlistButtonContainer>
        )}
        <Flex
          css={{
            flexDirection: "column",
            alignItems: "center",
            gap: "$4",
            top: 10,
            left: 10,
            position: "absolute",
            zIndex: 1,
          }}
        >
          {handleBookmark && (
            <BookmarkAndCollectionCircle
              analyticsEventPage={analyticsEventPage}
              analyticsProperty={analyticsProperty}
              onAddToCollection={
                onAddToCollection
                  ? () => onAddToCollection?.(talent)
                  : undefined
              }
              withTooltip={false}
              bookmarkCircleCss={{
                zIndex: 1,
                display: "block",
                transition: "opacity 0.25s",
                "@bp3": { opacity: 0 },
              }}
              checked={talent.bookmarked}
              onClick={(e) => {
                handleBookmark(
                  (e.target as HTMLInputElement).checked,
                  talent.id,
                );
                if (isBookmarkDisabled) {
                  e.preventDefault();
                }
              }}
              // React throws errors about a read-only form if
              // there is no onChange prop supplied to an input field
              onChange={() => {}}
              data-test-id="TalentCardBookmark"
              slideOutLeft={false}
              cta={bookmarkCta}
            />
          )}
          {instagram && <SocialMediaIcon platform="instagram" />}
          {tiktok && <SocialMediaIcon platform="tiktok" />}
          {youtube && <SocialMediaIcon platform="youtube" />}
        </Flex>
        {featuredImage && (
          <Box
            css={
              updatedTalentCard
                ? { display: "none", "@bp3": { display: "revert" } }
                : {}
            }
          >
            <ProfileLink>
              <AssetImage
                asset={featuredImage}
                size={{
                  "@initial": updatedTalentCard
                    ? {
                        width: 500,
                        height: 500,
                      }
                    : {
                        width: 324,
                        height: 422,
                      },
                  "@bp3": {
                    width: 324,
                    height: 422,
                  },
                }}
                containerCss={{
                  borderRadius: "14px",
                }}
                alt={`A picture of ${talent.name}`}
              />
              {updatedTalentCard && imageGalleryUrls.length > 3 && (
                <>
                  <BackgroundImage
                    css={{
                      "@bp3": {
                        background: `url(${
                          isTwicpicsEnabled
                            ? `${twicpicsUrl}${
                                new URL(imageGalleryUrls[1]).pathname
                              }?twic=v1/focus=smart/cover=322x422`
                            : `${urlAddQuery(
                                imageGalleryUrls[1],
                                "auto=format&fit=crop&dpr=1&crop=faces&w=324&h=422",
                              )}`
                        }) center`,
                        backgroundSize: "contain",
                        borderRadius: "14px",
                      },
                    }}
                  />
                  {imageGalleryUrls.map((url, i) => {
                    let imageSrc = urlAddQuery(
                      url,
                      "auto=format&fit=crop&dpr=1&crop=faces&w=324&h=422",
                    );

                    if (isTwicpicsEnabled) {
                      const path = new URL(url).pathname;
                      imageSrc = `${twicpicsUrl}${path}?twic=v1/focus=smart/cover=324x422`;
                    }

                    return (
                      <link
                        rel="preload"
                        as="image"
                        href={imageSrc}
                        key={`preloadURL${i}`}
                      />
                    );
                  })}
                </>
              )}
            </ProfileLink>
          </Box>
        )}
        {updatedTalentCard && imageGalleryUrls.length > 0 && (
          <ImageGallery
            assetsUrls={imageGalleryUrls}
            alt={`A picture ${
              profile.vertical === TalentVertical.FashionModel ? "of" : "by"
            } ${talent.name}`}
            css={{ display: "block", "@bp3": { display: "none" } }}
            context="gridView"
            onClick={() => {
              openTalentProfileModal && openTalentProfileModal(talent.slug);
            }}
          />
        )}
      </ImageContainer>

      <Meta>
        <Box
          css={
            updatedTalentCard
              ? { display: "flex", width: "100%", alignItems: "center" }
              : {
                  lineHeight: "$22",
                }
          }
        >
          <Flex
            css={{
              float: "right",
              alignItems: "flex-end",
              flexDirection: "column",
            }}
          >
            {!updatedTalentCard && averageRating && (
              <Review
                average={averageRating}
                total={totalReviews}
                css={{
                  ml: "$3",
                }}
              />
            )}
            {isRemovable(talent) && (
              <Button variant="unstyled" onClick={() => onRemove(talent)}>
                <Icon variant="cross" size={16} />
              </Button>
            )}
          </Flex>
          <Flex
            css={{
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Flex
              css={{
                alignItems: "center",
              }}
            >
              {updatedTalentCard && (
                <Tooltip
                  content={capitalize(verticalMap[profile.vertical])}
                  css={{ zIndex: "250" }}
                  hover="noMobileHover"
                >
                  <Icon
                    size={30}
                    variant={verticalIconMapAlt[profile.vertical]}
                    css={{ justifySelf: "flex-start", mr: "$3" }}
                  />
                </Tooltip>
              )}
              <ProfileLink>
                <Text variant="b2Bold">{talent.name}</Text>
              </ProfileLink>
            </Flex>
            <Flex
              css={{
                alignItems: "center",
              }}
            >
              {isFeatured && (
                <Tooltip
                  alignment="right"
                  css={{
                    zIndex: "250",
                    justifySelf: "flex-end",
                    marginLeft: "auto",
                  }}
                  wrapperCss={{
                    borderRadius: "4px",
                  }}
                  content="Featured Talent"
                  hover="noMobileHover"
                >
                  <Icon variant="featured" size={24} color="purple" />
                </Tooltip>
              )}
              {!!displayResponseTime && (
                <Tooltip
                  alignment="right"
                  css={{
                    zIndex: "250",
                    justifySelf: "flex-end",
                    marginLeft: "auto",
                  }}
                  content={`Typically responds in ${niceResponseTimeText(
                    responseTime,
                  )}`}
                  hover="noMobileHover"
                >
                  <Icon size={24} variant="responseTime" />
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Box>

        <Box>
          {updatedTalentCard ? (
            <Flex
              css={{
                height: "22px",
                justifyContent: "space-between",
                pt: "$3",
              }}
            >
              <Flex css={{ alignItems: "center", gap: "6px" }}>
                <Icon size={16} variant="location" />
                <Text variant="meta">{profile.primaryLocation.name}</Text>
              </Flex>
              <Flex css={{ alignItems: "center" }}>
                {averageRating && (
                  <Review
                    average={averageRating}
                    total={totalReviews}
                    textVariant={"mini"}
                    color="grey5"
                  />
                )}
              </Flex>
            </Flex>
          ) : (
            <SubHeading>
              {getSubHeading ? (
                getSubHeading(talent)
              ) : (
                <Flex css={{ alignItems: "center", flexWrap: "wrap" }}>
                  <InfoBanner
                    color="grey6"
                    variant="centered"
                    icon={verticalIconMap[profile.vertical] as AvailableIcon}
                    iconSize={14}
                  >
                    <Text variant="meta" color="grey6">
                      {capitalize(verticalMap[profile.vertical])}
                    </Text>
                  </InfoBanner>
                  <Text variant="meta" color="grey6" css={{ mx: "$3" }}>
                    &bull; p{" "}
                  </Text>
                  <Text variant="meta" color="grey6">
                    {profile.primaryLocation?.name || profile.location}
                  </Text>
                </Flex>
              )}
            </SubHeading>
          )}
        </Box>
      </Meta>
    </Container>
  );
};
