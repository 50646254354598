import { useContext } from "react";
import { styled, ComponentProps } from "@stitches/react";
import { useDroppable } from "@dnd-kit/core";
import { TwicImg } from "src/ccl/document/twicImg";
import { Flex } from "src/ccl/layout";
import { AvailableIcon, Icon, Img, Text } from "src/ccl/document";
import { Checkbox } from "src/ccl/data-entry";
import { pluralize } from "src/utils/lang";
import { Button } from "src/ccl/navigation";
import { MediaProvider, TwicpicsContext } from "src/contexts/Twicpics";

const DroppableListItemContainer = styled(Flex, {
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "$lg",
  backgroundColor: "$grey1",
  padding: "$3",
  "&:hover": { backgroundColor: "$grey2" },
  "@bp3": {
    padding: "10px",
  },
  variants: {
    disabled: {
      true: {
        opacity: 0.5,
        "&:hover": {
          backgroundColor: "$grey1",
        },
      },
    },

    inverted: {
      true: {
        backgroundColor: "$grey5",
        "&:hover": {
          backgroundColor: "$grey6",
        },
      },
    },
  },
  compoundVariants: [
    {
      disabled: true,
      inverted: true,
      css: {
        "&:hover": {
          backgroundColor: "$black",
        },
      },
    },
  ],
});

const MetaButton = ({
  icon,
  text,
  onClick,
}: {
  icon: AvailableIcon;
  text: string;
  onClick: () => void;
}) => (
  <Button
    onClick={onClick}
    variant="link"
    css={{
      color: "$white",
      "&:hover": { color: "$white", fontWeight: "$bold" },
    }}
  >
    <Flex css={{ gap: "$3" }}>
      <Icon variant={icon} size={14} color="white" />
      {text}
    </Flex>
  </Button>
);

export interface DroppableListItemProps
  extends ComponentProps<typeof DroppableListItemContainer> {
  withCheckbox?: boolean;
  name: string;
  droppableId?: string;
  count?: number;
  image?: string;
  emptyIcon?: string;
  isActive?: boolean;
  onClick: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  entity: string;
  imageSize?: number;
  disabled?: boolean;
}

export const DroppableListItem = ({
  withCheckbox = false,
  droppableId,
  name,
  count,
  image,
  emptyIcon,
  isActive = false,
  onClick,
  onEdit,
  onDelete,
  entity,
  imageSize = 70,
  disabled = false,
}: DroppableListItemProps) => {
  const { setNodeRef, isOver } = useDroppable({
    id: droppableId || name,
  });
  const isInverted = isOver || isActive;
  const showMetaButtons = isActive && (onEdit || onDelete);
  const isTwicpicsEnabled =
    useContext(TwicpicsContext).mediaProvider === MediaProvider.TWICPICS;
  if (isTwicpicsEnabled && image) {
    image = new URL(image).pathname;
  }

  return (
    <DroppableListItemContainer
      ref={setNodeRef}
      data-test-id="BookmarkCollectionListItem"
      disabled={disabled}
      inverted={isInverted}
      onClick={disabled ? undefined : onClick}
      css={{ cursor: disabled ? "not-allowed" : "pointer" }}
    >
      <Flex css={{ alignItems: showMetaButtons ? undefined : "center" }}>
        {image ? (
          isTwicpicsEnabled ? (
            <TwicImg
              css={{
                display: "inline",
                borderRadius: "8px",
                objectFit: "cover",
                width: imageSize,
                height: imageSize,

                "@bp3": { height: "$megaAvatar", width: "$megaAvatar" },
              }}
              src={image}
            />
          ) : (
            <Img
              css={{
                display: "inline",
                borderRadius: "8px",
                objectFit: "cover",
                width: imageSize,
                height: imageSize,

                "@bp3": { height: "$megaAvatar", width: "$megaAvatar" },
              }}
              src={
                image.match(/imgix/)
                  ? `${image}?w=100&ar=1:1&crop=faces&fit=crop`
                  : image
              }
            />
          )
        ) : (
          <Flex
            css={{
              width: imageSize,
              height: imageSize,
              borderRadius: 8,
              backgroundColor: "$grey2",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Img src={emptyIcon} />
          </Flex>
        )}
        <Flex
          css={{
            flex: 1,
            my: "$2",
            flexDirection: "column",
            pl: "10px",
            justifyContent: "space-between",
          }}
        >
          <Text variant="b3Bold" color={isInverted ? "white" : "black"}>
            {name}
          </Text>
          <Text variant="b3" color={isInverted ? "grey3" : "grey6"}>
            {count ? pluralize(count, entity) : ""}
          </Text>

          {showMetaButtons && (
            <Flex css={{ gap: "$4" }}>
              {onEdit && (
                <MetaButton text="Edit" icon="pencil" onClick={onEdit} />
              )}
              {onDelete && (
                <MetaButton text="Delete" icon="trash" onClick={onDelete} />
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
      {withCheckbox && (
        <Checkbox
          checked={isInverted}
          variant="inverse"
          onChange={() => null}
          css={{
            width: 25,
            height: 25,
            cursor: "pointer",
            "&::before": { backgroundImage: "unset" },
            "@bp3": {
              width: 30,
              height: 30,
            },
          }}
        />
      )}
    </DroppableListItemContainer>
  );
};
