import { useMutation } from "@apollo/client";
import { includes, noop } from "lodash";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Avatar } from "src/ccl/blocks";
import { Toggle } from "src/ccl/data-entry";
import { AvailableIcon, ConfirmModal, Icon, Text } from "src/ccl/document";
import { Tooltip } from "src/ccl/feedback";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Button, Dropdown, ExternalLink, Link } from "src/ccl/navigation";
import { tokens } from "src/ccl/stitches/theme";
import { PopupNotification } from "src/components/PopupNotification";
import { DeclineRequestModal } from "src/components/dashboards/agents/talentManagement/DeclineRequestModal";
import { VisibilityPreferencesModal } from "src/components/dashboards/agents/talentManagement/VisibilityPreferencesModal";
import {
  Agency,
  Mutation,
  MutationDeleteAgencyTalentArgs,
  MutationResendOnboardEmailArgs,
  MutationSendPaymentVerificationEmailArgs,
  MutationUpdateAgencyTalentArgs,
  SocialMediaPlatform,
  TalentProfile,
  TalentVertical,
  User,
} from "src/graphql/generated/types";
import {
  DELETE_AGENCY_TALENT_MUTATION,
  SEND_PAYMENT_VERIFICATION_EMAIL_MUTATION,
  UPDATE_AGENCY_TALENT_MUTATION,
} from "src/graphql/mutations";
import { RESEND_ONBOARD_EMAIL_MUTATION } from "src/graphql/mutations/agent/ResendOnboardEmail";
import {
  useFeatures,
  useStoreModel,
  useToggle,
  useUserKindContext,
} from "src/hooks";
import { sendToAmplitude, trackEvent } from "src/utils/analytics";
import { copyToClipboard } from "src/utils/clipboard";
import { formatCurrency } from "src/utils/currencyFormatter";
import { formatDate } from "src/utils/dates";
import { getInitials } from "src/utils/lang";
import { getSocialMedia } from "src/utils/socialMedia";
import { pronounsMap } from "src/utils/user";
import { urlAddQuery } from "src/utils/url";
import { basePathname } from "src/utils/pathname";

const DropdownItem = ({
  text,
  icon,
  color,
  disabled,
  onClick,
}: {
  text: string;
  icon: AvailableIcon;
  color?: keyof typeof tokens.colors;
  disabled: boolean;
  onClick: () => void;
}) => (
  <Flex
    onClick={disabled ? undefined : onClick}
    css={{
      gap: "10px",
      p: "$4",
      borderRadius: "8px",
      alignItems: "center",
      cursor: disabled ? undefined : "pointer",
      "&:hover": { backgroundColor: disabled ? undefined : "$grey1" },
      opacity: disabled ? 0.5 : undefined,
    }}
  >
    <Icon variant={icon} size={24} color={color} />
    <Text variant="b2">{text}</Text>
  </Flex>
);

const InfoBlock = ({
  icon,
  title,
  onClick,
  externalLink,
}: {
  icon: AvailableIcon;
  title: string;
  onClick?: () => void;
  externalLink?: string;
}) => (
  <Flex
    css={{
      gap: "$3",
    }}
  >
    <Flex
      css={{
        background: "$grey1",
        width: "30px",
        height: "30px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "$pill",
      }}
    >
      <Icon variant={icon} size={18} />
    </Flex>
    {externalLink ? (
      <ExternalLink
        to={externalLink}
        target="_blank"
        css={{ textDecoration: "none" }}
      >
        <Text variant="b2">{title}</Text>
      </ExternalLink>
    ) : (
      <Text
        css={{ cursor: onClick ? "pointer" : "default" }}
        variant="b2"
        onClick={onClick}
      >
        {title}
      </Text>
    )}
  </Flex>
);

const TrustshareStatus = ({ verified }: { verified: boolean }) => (
  <Box
    css={{
      background: verified ? "$positiveLight" : "$pendingLight",
      borderRadius: "$pill",
      padding: "$3 $4",
      width: "fit-content",
    }}
  >
    <Text variant="b3Bold" css={{ color: verified ? "$darkGreen" : "#C55405" }}>
      {verified ? "Trustshare verified" : "Pending Trustshare verification"}
    </Text>
  </Box>
);

const Stat = ({
  testId,
  iconVariant,
  value,
  description,
}: {
  testId: string;
  iconVariant: AvailableIcon;
  value: React.ReactNode;
  description: React.ReactNode;
}) => (
  <Flex data-test-id={testId} css={{ alignItems: "center", gap: "14px" }}>
    <Icon
      variant={iconVariant}
      size={40}
      css={{
        "@sm": {
          display: "none",
        },
        "@lg": {
          display: "block",
        },
      }}
    />
    <Box>
      <Text variant="nh3">{value}</Text>
      <Text variant="b2">{description}</Text>
    </Box>
  </Flex>
);

const avatarSize = 150;

interface TalentOverviewHeaderProps {
  agency?: Agency;
  talent: User;
  isContactSelect?: boolean;
}

export const TalentOverviewHeader = ({
  agency,
  talent,
  isContactSelect,
}: TalentOverviewHeaderProps) => {
  const { featureEnabled } = useFeatures();
  const { pathname } = useLocation();
  const history = useHistory();

  const currentUser = useStoreModel("currentUser");
  const { isFirstPartyAgent } = currentUser;
  const userKindContext = useUserKindContext(currentUser);

  const profile = talent.profile as TalentProfile;
  const successfulJobCount = talent.successfulJobCount || 0;
  const shortlistedJobCount = talent.shortlistCount || 0;
  const name = talent.name;
  const phoneNumber = talent.phoneNumber;
  const emailAddress = talent.email;
  const dateOfBirth = profile.dateOfBirth;
  const primaryLocationName = profile.primaryLocation.name;
  const pronouns = profile.pronouns;
  const createdAt = talent.createdAt;
  const isFeatured = isFirstPartyAgent
    ? profile.isFeatured
    : profile.isAgencyFeatured;

  const totalPay = talent.totalPay || 0;
  const averageJobPay = talent.averageJobPay || 0;
  const featuredImage = talent.featuredAsset
    ? urlAddQuery(
        talent.featuredAsset?.mediaUrl,
        `w=${avatarSize}&h=${avatarSize}&crop=faces&fit=crop`,
      )
    : undefined;
  const avatarInitials = getInitials(talent?.name);
  const vertical = profile.vertical;

  const unhideContactSelectRequest = profile?.unhideContactSelectRequest;

  const [active, setActive] = useState(false);
  const [content, setContent] = useState("");
  const [isShowingDeleteProfileModal, setIsShowingDeleteProfileModal] =
    useState(false);

  const [resentOnboardingEmail, setResentOnboardingEmail] = useState(false);
  const [resentPaymentVerificationEmail, setResentPaymentVerificationEmail] =
    useState(false);
  const [showPopupNotification, setShowPopupNotification] = useState(false);

  const [visibilityModalOpen, toggleVisibilityModal] = useToggle();

  const [showMoveToContactSelectRequest, setShowMoveToContactSelectRequest] =
    useState<boolean>(unhideContactSelectRequest?.status === "pending");

  const [declineRequestModal, toggleDeclineRequestModal] = useToggle();

  const [updateAgencyTalent, { loading: updateTalentLoading }] = useMutation<
    Mutation,
    MutationUpdateAgencyTalentArgs
  >(UPDATE_AGENCY_TALENT_MUTATION);

  const [deleteTalent, { loading: deleteTalentLoading }] =
    useMutation<MutationDeleteAgencyTalentArgs>(DELETE_AGENCY_TALENT_MUTATION);

  const [resendOnboardingEmail, { error: resendOnboardingError }] = useMutation<
    Mutation,
    MutationResendOnboardEmailArgs
  >(RESEND_ONBOARD_EMAIL_MUTATION, {
    variables: {
      userId: talent?.id || "",
    },
    onCompleted: (data) => {
      if (data.resendOnboardEmail?.id) {
        setResentOnboardingEmail(true);
        setTimeout(() => setResentOnboardingEmail(false), 5000);
      }
    },
    onError: noop,
  });

  const [
    resendPaymentVerificationEmail,
    { error: resendPaymentVerificationError },
  ] = useMutation<Mutation, MutationSendPaymentVerificationEmailArgs>(
    SEND_PAYMENT_VERIFICATION_EMAIL_MUTATION,
    {
      variables: {
        userId: talent?.id || "",
      },
      onCompleted: (data) => {
        if (data.sendPaymentVerificationEmail?.id) {
          setResentPaymentVerificationEmail(true);
          setTimeout(() => setResentPaymentVerificationEmail(false), 5000);
        }
      },
      onError: noop,
    },
  );

  const onApprovedMoveRequest = async () => {
    await updateAgencyTalent({
      variables: {
        talentId: talent.id,
        talentProfile: {
          unhideContactSelectRequest: {
            status: "approved",
          },
        },
      },
    });
  };

  const onDeclineMoveRequest = async (declineReason: string) => {
    await updateAgencyTalent({
      variables: {
        talentId: talent.id,
        talentProfile: {
          unhideContactSelectRequest: {
            status: "declined",
            declineReason,
          },
        },
      },
    });
  };

  const toggleFeatured = async (value: boolean) => {
    if (isFeatured === value) return;
    await updateAgencyTalent({
      variables: {
        talentId: talent.id,
        talentProfile: {
          isContactOpen: profile.isContactOpen,
          isContactSelect: profile.isContactSelect,
          ...(isFirstPartyAgent
            ? { isFeatured: value }
            : { isAgencyFeatured: value }),
        },
      },
    });

    setShowPopupNotification(true);
    setTimeout(() => {
      setShowPopupNotification(false);
    }, 6000);
  };

  const instragram = getSocialMedia(profile, SocialMediaPlatform.Instagram);

  const title = () => {
    if (includes(pathname, "/open")) {
      return "open";
    } else if (includes(pathname, "/select")) {
      return "select";
    }

    return "all";
  };

  const basePath = basePathname(pathname, agency?.id);

  return (
    <Flex
      css={{
        flexDirection: "column",
        gap: "$8",
      }}
    >
      {showPopupNotification && (
        <PopupNotification
          showClose
          onClose={() => setShowPopupNotification(false)}
        >
          <Text>
            {name} {isFeatured ? "added to" : "removed from"} featured talent
          </Text>
        </PopupNotification>
      )}

      <Link
        to={basePath}
        css={{ textDecoration: "none", width: "fit-content" }}
      >
        <Icon variant="chevronLeft" size={12} />
        <Text
          as="span"
          css={{
            paddingLeft: "11px",
            fontFamily: "$sansNew",
            fontSize: "$14",
            fontWeight: "$medium",
            lineHeight: "19px",
            letterSpacing: "0.02em",
            textDecoration: "underline",
          }}
        >
          Back to {title()} talent
        </Text>
      </Link>

      {showMoveToContactSelectRequest && (
        <Flex
          css={{
            background: "$lightOrange",
            padding: "$8",
            borderRadius: "$lg",
            justifyContent: "space-between",
          }}
        >
          <Flex css={{ flexDirection: "column", gap: "10px" }}>
            <Flex css={{ alignItems: "center", gap: "15px" }}>
              <Icon variant="linkOut" size={18} />
              <Text variant="title">
                Request to move talent to Contact Select
              </Text>
            </Flex>
            <Text variant="b2">
              Agency has requested this talent to be moved to Contact Select,
              because:
              <br />
              <Text as="span" variant="b2" css={{ fontStyle: "italic" }}>
                &quot;{unhideContactSelectRequest?.requestReason}&quot;
              </Text>
            </Text>
          </Flex>
          <Flex css={{ flexDirection: "column", gap: "$5" }}>
            <Button variant="primaryCta" onClick={onApprovedMoveRequest}>
              Approve
            </Button>
            <Button
              variant="secondaryCta"
              onClick={toggleDeclineRequestModal}
              css={{
                background: "$rejectedLight",
                color: "$red",
                borderColor: "$red",
              }}
            >
              Decline
            </Button>
          </Flex>
        </Flex>
      )}
      <Flex
        css={{
          flexDirection: "column",
          gap: "$6",
        }}
      >
        <Flex
          css={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "start",
            "@md": {
              alignItems: "start",
            },
          }}
        >
          <Flex
            css={{
              alignItems: "center",
              gap: "21px",
              "@md": {
                alignItems: "start",
              },
              "@lg": {
                gap: "$9",
              },
            }}
          >
            <Flex
              css={{
                flexDirection: "column",
                alignItems: "center",
                gap: "14px",
              }}
            >
              <Avatar
                testId="TalentOverviewAvatar"
                initials={avatarInitials}
                imageUrl={featuredImage}
                fontVariant="impact"
                css={{
                  backgroundColor: featuredImage ? "$white" : "$black",
                  width: "69px",
                  height: "69px",
                  "@lg": {
                    width: "133px",
                    height: "133px",
                  },
                  "@xl": {
                    width: "167px",
                    height: "167px",
                  },
                }}
                isContactSelect={isContactSelect}
              />
              <Box
                css={{
                  display: "none",
                  "@md": {
                    display: "block",
                  },
                }}
              >
                <Box
                  css={{
                    background: isFeatured ? "$positiveLight" : "$grey1",
                    padding: "6px 8px",
                    borderRadius: "7px",
                    width: "fit-content",
                  }}
                >
                  <Toggle
                    id="isFeaturedToggle"
                    textChecked={
                      <Flex css={{ alignItems: "center", gap: "$2" }}>
                        <Icon variant="featured" size={18} /> Featured
                      </Flex>
                    }
                    checked={isFeatured ?? false}
                    onCheck={() => toggleFeatured(true)}
                    onUncheck={() => toggleFeatured(false)}
                    iconChecked={<Icon variant="check" size={10} />}
                    iconUnchecked={
                      <Icon variant="cross" size={10} color="white" />
                    }
                    uncheckedGreyBackground
                    height={21}
                  />
                </Box>
              </Box>
            </Flex>
            <Flex
              css={{
                flexDirection: "column",
                gap: "26px",
              }}
            >
              <Flex css={{ flexDirection: "column", gap: "14px" }}>
                <Flex
                  css={{ alignItems: "center", gap: "$3", flexWrap: "wrap" }}
                >
                  <Text variant="nh3">{name}</Text>
                  <Text variant="b2" color="grey5">
                    Joined {formatDate(createdAt, "MMM dd, yyyy")}
                  </Text>
                </Flex>
                <Box
                  css={{
                    "@md": {
                      display: "none",
                    },
                  }}
                >
                  <Box
                    css={{
                      background: isFeatured ? "$positiveLight" : "$grey1",
                      padding: "6px 8px",
                      borderRadius: "7px",
                      width: "fit-content",
                    }}
                  >
                    <Toggle
                      id="isFeaturedToggle"
                      textChecked="Featured"
                      checked={isFeatured ?? false}
                      onCheck={() => toggleFeatured(true)}
                      onUncheck={() => toggleFeatured(false)}
                      iconChecked={<Icon variant="check" size={10} />}
                      iconUnchecked={
                        <Icon variant="cross" size={10} color="white" />
                      }
                      uncheckedGreyBackground
                      height={21}
                    />
                  </Box>
                </Box>
              </Flex>
              <Flex
                css={{
                  display: "none",
                  "@sm": {
                    gap: "42px",
                  },
                  "@md": {
                    display: "flex",
                    flexWrap: "wrap",
                  },
                }}
              >
                <Flex
                  css={{
                    flexDirection: "column",
                    gap: "$4",
                  }}
                >
                  {emailAddress && (
                    <Flex css={{ flexDirection: "column" }}>
                      <Tooltip content={content} active={active} />
                      <InfoBlock
                        icon="plane"
                        title={emailAddress}
                        onClick={() => {
                          setContent("Copied!");
                          setActive(true);
                          copyToClipboard(emailAddress);
                          setTimeout(() => {
                            setActive(false);
                          }, 3000);
                        }}
                      />
                    </Flex>
                  )}
                  {phoneNumber && (
                    <InfoBlock icon="phone" title={phoneNumber} />
                  )}
                  {instragram && (
                    <InfoBlock
                      icon="instagram"
                      title={instragram.handle}
                      externalLink={`https://www.instagram.com/${instragram.handle}`}
                    />
                  )}
                </Flex>
                <Flex
                  css={{
                    flexDirection: "column",
                    gap: "$4",
                  }}
                >
                  {dateOfBirth && (
                    <InfoBlock
                      icon="calendar"
                      title={formatDate(dateOfBirth, "dd/MM/yyyy")}
                    />
                  )}
                  {primaryLocationName && (
                    <InfoBlock icon="location" title={primaryLocationName} />
                  )}
                  {pronouns && (
                    <InfoBlock icon="userCheck" title={pronounsMap[pronouns]} />
                  )}
                </Flex>
                {isFirstPartyAgent && (
                  <Flex
                    css={{
                      flexDirection: "column",
                      gap: "$4",
                    }}
                  >
                    <InfoBlock
                      icon="plane"
                      title={
                        resendOnboardingError
                          ? "Something went wrong..."
                          : resentOnboardingEmail
                          ? "Sent!"
                          : "Resend onboarding email"
                      }
                      onClick={() =>
                        !resentOnboardingEmail && resendOnboardingEmail()
                      }
                    />
                    <InfoBlock
                      icon="plane"
                      title={
                        resendPaymentVerificationError
                          ? "Something went wrong..."
                          : resentPaymentVerificationEmail
                          ? "Sent!"
                          : "Resend Trustshare verification email"
                      }
                      onClick={() =>
                        !resentPaymentVerificationEmail &&
                        resendPaymentVerificationEmail()
                      }
                    />
                  </Flex>
                )}
              </Flex>
              <Box
                css={{
                  display: "none",
                  "@md": {
                    display: "block",
                  },
                }}
              >
                <TrustshareStatus verified={!!talent.trustshareVerified} />
              </Box>
            </Flex>
          </Flex>
          <Dropdown
            bodyCss={{
              borderRadius: "8px",
              p: "$5",
              width: 300,
              border: "unset",
            }}
            position="right"
            launcher={
              <Button
                data-test-id="More-Options"
                variant="circle"
                css={{
                  background: "$black",
                  "&:hover": { backgroundColor: "$grey1" },
                }}
              >
                <Icon variant="moreDots" size={18} color="white" />
              </Button>
            }
          >
            <DropdownItem
              text="Edit profile"
              icon="pencil"
              color="black"
              disabled={false}
              onClick={() =>
                history.push(`${basePath}/${talent.slug}/edit/profile`)
              }
            />
            <DropdownItem
              text="Download PDF card"
              icon="download"
              color="black"
              disabled={false}
              onClick={() => {
                if (profile.cardUrl) {
                  window.open(profile.cardUrl, "_blank");
                }
              }}
            />
            <DropdownItem
              text="Preview profile"
              icon="eye"
              color="black"
              disabled={false}
              onClick={() => {
                if (featureEnabled("unified_dashboards")) {
                  window.open(
                    `/creatives/${talent.slug}?preview=true`,
                    "_blank",
                  );
                } else {
                  window.open(`/creatives/${talent.slug}`, "_blank");
                }
              }}
            />
            <DropdownItem
              text="Set visibility preferences"
              icon="eyeHidden"
              color="black"
              disabled={updateTalentLoading}
              onClick={toggleVisibilityModal}
            />
            <DropdownItem
              text="Delete profile"
              icon="trash"
              color="red"
              disabled={false}
              onClick={() => {
                if (talent.slug !== "") {
                  setIsShowingDeleteProfileModal(true);
                }
              }}
            />
          </Dropdown>
        </Flex>
        <Flex
          css={{
            flexDirection: "column",
            gap: "$4",
            "@sm": {
              flexDirection: "row",
              gap: "42px",
            },
            "@md": {
              display: "none",
            },
          }}
        >
          <Flex
            css={{
              flexDirection: "column",
              gap: "$4",
            }}
          >
            {emailAddress && (
              <Flex css={{ flexDirection: "column" }}>
                <Tooltip content={content} active={active} />
                <InfoBlock
                  icon="plane"
                  title={emailAddress}
                  onClick={() => {
                    setContent("Copied!");
                    setActive(true);
                    copyToClipboard(emailAddress);
                    setTimeout(() => {
                      setActive(false);
                    }, 3000);
                  }}
                />
              </Flex>
            )}
            {phoneNumber && <InfoBlock icon="phone" title={phoneNumber} />}
            {instragram && (
              <InfoBlock
                icon="instagram"
                title={instragram.handle}
                externalLink={`https://www.instagram.com/${instragram.handle}`}
              />
            )}
          </Flex>
          <Flex
            css={{
              flexDirection: "column",
              gap: "$4",
            }}
          >
            {dateOfBirth && (
              <InfoBlock
                icon="calendar"
                title={formatDate(dateOfBirth, "dd/MM/yyyy")}
              />
            )}
            {primaryLocationName && (
              <InfoBlock icon="location" title={primaryLocationName} />
            )}
            {pronouns && (
              <InfoBlock icon="userCheck" title={pronounsMap[pronouns]} />
            )}
          </Flex>
          {isFirstPartyAgent && (
            <Flex
              css={{
                flexDirection: "column",
                gap: "$4",
              }}
            >
              <InfoBlock
                icon="plane"
                title={
                  resendOnboardingError
                    ? "Something went wrong..."
                    : resentOnboardingEmail
                    ? "Sent!"
                    : "Resend onboarding email"
                }
                onClick={() =>
                  !resentOnboardingEmail && resendOnboardingEmail()
                }
              />
              <InfoBlock
                icon="plane"
                title={
                  resendPaymentVerificationError
                    ? "Something went wrong..."
                    : resentPaymentVerificationEmail
                    ? "Sent!"
                    : "Resend Trustshare verification email"
                }
                onClick={() =>
                  !resentPaymentVerificationEmail &&
                  resendPaymentVerificationEmail()
                }
              />
            </Flex>
          )}
        </Flex>
        <Box
          css={{
            "@md": {
              display: "none",
            },
          }}
        >
          <TrustshareStatus verified={!!talent.trustshareVerified} />
        </Box>
      </Flex>
      <Grid
        css={{
          background: "$grey1",
          borderRadius:
            vertical === TalentVertical.FashionModel ? "$lg $lg 0 0" : "$lg",
          padding: "$8",

          gridColumns: 1,
          gridGap: "18px",
          flexDirection: "column",

          "@sm": {
            gridColumns: 3,
          },
        }}
      >
        <Stat
          testId="completedJobCount"
          iconVariant="check"
          value={successfulJobCount}
          description={
            successfulJobCount === 1 ? "completed job" : "completed jobs"
          }
        />
        <Stat
          testId="shortlistCount"
          iconVariant="checklist"
          value={shortlistedJobCount}
          description={shortlistedJobCount === 1 ? "shortlist" : "shortlists"}
        />
        <Stat
          testId="totalPay"
          iconVariant="moneyBudget"
          value={formatCurrency(totalPay)}
          description="total pay"
        />
        <Stat
          testId="averagePay"
          iconVariant="average"
          value={formatCurrency(averageJobPay)}
          description="average pay per job"
        />
        {vertical === TalentVertical.FashionModel && (
          <>
            <Stat
              testId="packageCount"
              iconVariant="package"
              value={talent.packageCount}
              description={talent.packageCount === 1 ? "package" : "packages"}
            />
            <Stat
              testId="convertedPackageCount"
              iconVariant="convertedFromPackage"
              value={talent.convertedPackageCount}
              description={
                talent.convertedPackageCount === 1
                  ? "package converted"
                  : "packages converted"
              }
            />
          </>
        )}
      </Grid>
      <ConfirmModal
        title="Delete profile"
        confirmButtonTitle="Yes, delete it"
        onCancel={() => setIsShowingDeleteProfileModal(false)}
        onConfirm={() => {
          deleteTalent({
            variables: {
              id: talent.id,
            },
          });
          sendToAmplitude("creative profile - deletes creative", {
            url: pathname,
            userType: userKindContext,
            agency: agency?.name,
          });
          history.push(basePath);
          trackEvent("agencyDashboard:deleteTalent");
        }}
        isDisabled={deleteTalentLoading}
        isOpen={isShowingDeleteProfileModal}
      >
        <Text>Are you sure you want to delete this profile?</Text>
      </ConfirmModal>
      <VisibilityPreferencesModal
        isOpen={visibilityModalOpen}
        onClose={toggleVisibilityModal}
        talent={talent}
      />
      <DeclineRequestModal
        isOpen={declineRequestModal}
        onClose={() => {
          toggleDeclineRequestModal();
          setShowMoveToContactSelectRequest(false);
        }}
        onDecline={onDeclineMoveRequest}
      />
    </Flex>
  );
};
