import { useContext } from "react";
import { Icon, AvailableIcon, Img } from "src/ccl/document";
import { Box } from "src/ccl/layout";
import { MediaProvider, TwicpicsContext } from "src/contexts/Twicpics";
import { FieldOption } from "src/graphql/types";

interface RadioImageProps {
  option: FieldOption;
  selected?: boolean;
}

export const RadioImage = ({ option, selected }: RadioImageProps) => {
  const isTwicpicsEnabled =
    useContext(TwicpicsContext).mediaProvider === MediaProvider.TWICPICS;

  if (option.imageUrl) {
    return (
      <Img
        src={
          isTwicpicsEnabled
            ? option.imageUrl.replace("imgix.net", "twic.pics")
            : option.imageUrl
        }
        css={{ width: 46, height: 46, ml: "5px", borderRadius: "$0" }}
      />
    );
  } else if (option.icon) {
    return (
      <Icon
        variant={option.icon as AvailableIcon}
        size={28}
        color={selected ? "black" : "grey6"}
      />
    );
  } else {
    return (
      <Box
        css={{
          backgroundColor: `#${option.colour}`,
          minWidth: 46,
          height: 46,
          ml: "5px",
          borderRadius: "$0",
        }}
      />
    );
  }
};
