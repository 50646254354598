import { gql } from "@apollo/client";

export const CREATE_MESSAGE_MUTATION = gql`
  mutation CreateMessage(
    $content: String!
    $chatroomId: ID!
    $messageAssets: [FileUploadInput!]
  ) {
    addMessageToChatroom(
      content: $content
      chatroomId: $chatroomId
      messageAssets: $messageAssets
    ) {
      id
      content
      createdAt
      chatroom {
        id
      }
      messageAssets {
        height
        id
        mediaUrl
        width
      }
      sender {
        id
      }
    }
  }
`;
