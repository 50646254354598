import { gql } from "@apollo/client";

export const CREATE_CHATROOM_MUTATION = gql`
  mutation CreateChatroom(
    $message: String!
    $userSlugs: [String!]!
    $jobId: ID!
    $name: String!
    $messageAssets: [FileUploadInput!]
  ) {
    createChatroom(
      userSlugs: $userSlugs
      jobId: $jobId
      message: $message
      name: $name
      messageAssets: $messageAssets
    ) {
      id
      name
    }
  }
`;
