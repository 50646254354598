import { capitalize, map, size } from "lodash";
import { useEffect, useMemo } from "react";
import { styled } from "src/ccl";
import { SelectReact, SelectReactWithInput } from "src/ccl/data-entry";
import { Icon, Text } from "src/ccl/document";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Button } from "src/ccl/navigation";
import {
  MediaLocation,
  MediaType,
  MutationCreateJobArgs,
  UsageInput,
} from "src/graphql/types";
import { UsageMediaLocationMap, UsageMediaTypeMap } from "src/utils/job";
import { uppercaseFirstLetterOfEachWord } from "src/utils/lang";

const mediaTypeOptions = Object.entries(UsageMediaTypeMap).map(
  ([key, value]) => ({
    label: value,
    value: key,
  }),
);

const mediaLocationOptions = Object.entries(UsageMediaLocationMap).map(
  ([key, value]) => ({
    label: value,
    value: key,
  }),
);

const mediaFormOptions = ["print", "digital", "internal", "other"].map((o) => ({
  label: capitalize(o),
  value: o.toUpperCase(),
}));

const mediaLengthOptions = [
  "years",
  "months",
  "weeks",
  "days",
  "in perpetuity",
].map((o) => ({
  label: capitalize(o),
  value: o.toUpperCase(),
}));

const HeaderTitle = styled(Text, {
  fontWeight: "600",
});

const MobileLabel = styled(Text, {
  "@bp2": {
    display: "none",
  },
});

const LengthItem = ({ onChange, usage }: UsageFieldProps) => {
  return (
    <>
      <MobileLabel
        css={{
          mb: "$4",
        }}
      >
        How long for?
      </MobileLabel>
      <SelectReactWithInput
        id="MediaLengthField"
        placeholder="e.g 2"
        options={mediaLengthOptions}
        value={usage?.length}
        onChange={(value) =>
          onChange({
            ...usage,
            length: value.includes("IN PERPETUITY") ? "IN PERPETUITY" : value,
          })
        }
        isDisabled={usage?.length?.includes("IN PERPETUITY")}
        inputCSS={{
          flex: 1,
          minWidth: "35px",
        }}
      />
    </>
  );
};

interface UsageFieldProps {
  onChange: (usage: Partial<UsageInput>) => void;
  usage?: Partial<UsageInput>;
}

const UsageField = ({ onChange, usage }: UsageFieldProps) => {
  return (
    <Box
      css={{
        borderLeft: "4px solid $grey3",
        display: "grid",
        gap: "$4",
        pl: "$5",
        "@bp2": {
          display: "contents",
          borderLeft: "none",
          pl: "$0",
        },
      }}
    >
      <MobileLabel>Media Type</MobileLabel>
      <SelectReact
        id="MediaTypeField"
        placeholder="Choose"
        variant="rebrand"
        onChange={(value) =>
          onChange({ ...usage, mediaType: value?.value as MediaType })
        }
        options={mediaTypeOptions}
        value={mediaTypeOptions.find((o) => o.value === usage?.mediaType)}
      />

      <MobileLabel>Location</MobileLabel>
      <SelectReact
        id="MediaLocationField"
        placeholder="Choose"
        variant="rebrand"
        onChange={(value) =>
          onChange({ ...usage, location: value?.value as MediaLocation })
        }
        options={mediaLocationOptions}
        value={mediaLocationOptions.find((o) => o.value === usage?.location)}
      />

      <MobileLabel>Form</MobileLabel>
      <SelectReact
        id="MediaFormField"
        placeholder="Choose"
        variant="rebrand"
        onChange={(value) =>
          onChange({ ...usage, form: value ? [value.value] : undefined })
        }
        options={mediaFormOptions}
        value={mediaFormOptions.find((o) => o.value === usage?.form?.[0])}
      />
      <Box
        css={{
          "@bp2": {
            display: "none",
          },
        }}
      >
        <LengthItem usage={usage} onChange={onChange} />
      </Box>
    </Box>
  );
};

const UsageItem = ({
  onChange,
  usage,
  onDelete,
}: UsageFieldProps & { onDelete?: () => void }) => {
  const isValid =
    !!usage && !!usage.mediaType && !!usage.location && !!usage.form?.[0];
  const label = isValid
    ? `${uppercaseFirstLetterOfEachWord(
        (usage.form?.[0] as string).toLowerCase().replaceAll("_", " "),
      )} - ${UsageMediaTypeMap[usage.mediaType as MediaType]}, ${
        UsageMediaLocationMap[usage.location as MediaLocation]
      }`
    : "Enter information above.";

  const hasDeleteFunc = useMemo(
    () => typeof onDelete !== "undefined",
    [onDelete],
  );

  return (
    <>
      <Box
        css={{
          p: "$5",
          backgroundColor: "$grey1",
        }}
      >
        <Text>{label}</Text>
      </Box>
      <LengthItem usage={usage} onChange={onChange} />
      {hasDeleteFunc && (
        <Box
          onClick={onDelete}
          css={{
            cursor: onDelete ? "pointer" : "initial",
          }}
        >
          <Icon
            variant="trash"
            color={onDelete ? "grey4" : "grey3"}
            size={24}
          />
        </Box>
      )}
    </>
  );
};

interface UsagesProps {
  usages: MutationCreateJobArgs["usages"];
  onChange: (usages: Partial<UsageInput>[]) => void;
}

const InputsWrapper = styled(Grid, {
  rowGap: "$9",
  columnGap: "$9",
  mb: "$9",

  "@bp2": {
    rowGap: "$4",
    gridTemplateColumns: "repeat(3, 1fr);",
  },
});

const InputsHeader = () => (
  <>
    <HeaderTitle>Media Type</HeaderTitle>
    <HeaderTitle>Location</HeaderTitle>
    <HeaderTitle>Form</HeaderTitle>
  </>
);

const ItemsWrapper = styled(Grid, {
  display: "none",

  rowGap: "$4",
  columnGap: "$9",
  "@bp2": {
    display: "grid",
    gridTemplateColumns: "3fr 2fr 1fr",
    alignItems: "center",
  },
});

const ItemsHeader = () => (
  <>
    <HeaderTitle>Usage</HeaderTitle>
    <HeaderTitle>How long for?</HeaderTitle>
    {/** needed to fill the empty space above the trash bit for the grid*/}
    <Box></Box>
  </>
);

const CTA = ({ onClick }: { onClick: () => void }) => (
  <Button
    variant="unstyled"
    onClick={onClick}
    css={{
      display: "flex",
      alignItems: "center",
      mb: "$9",
    }}
  >
    <Flex
      css={{
        borderRadius: "$round",
        backgroundColor: "$grey4",
        alignItems: "center",
        justifyContent: "center",
        p: "$2",
        mr: "$3",
      }}
    >
      <Icon variant="plus" size={8} color="white" />
    </Flex>
    <Text variant="meta">Add another</Text>
  </Button>
);

export const Usages = ({ usages, onChange }: UsagesProps) => {
  const emptyUsage: Partial<UsageInput> = {
    mediaType: undefined,
    location: undefined,
    form: [],
    length: "",
  };

  const handler = (cb: (newUsages: Partial<UsageInput>[]) => void) => {
    const newUsages: Partial<UsageInput>[] = usages ? [...usages] : [];
    cb(newUsages);
    onChange(newUsages);
  };

  const handleChange = (usage: Partial<UsageInput>, index: number) => {
    /* eslint-disable unused-imports/no-unused-vars, @typescript-eslint/naming-convention */
    const { __typename, ...rest } = usage;
    /* eslint-enable unused-imports/no-unused-vars, @typescript-eslint/naming-convention */

    handler((newUsages) => (newUsages[index] = rest));
  };

  const handleDelete = (index: number) => {
    handler((newUsages) => newUsages.splice(index, 1));
  };

  const handleClick = () => {
    handler((newUsages) => newUsages.push(emptyUsage));
  };

  useEffect(() => {
    if (!usages) {
      onChange([emptyUsage]);
    }
  }, [usages]);

  return (
    <>
      <InputsWrapper>
        <Box
          css={{
            display: "none",
            "@bp2": {
              display: "contents",
            },
          }}
        >
          <InputsHeader />
        </Box>
        {usages?.map((usage, index) => (
          <UsageField
            usage={usage}
            key={`usageField-${index}`}
            onChange={(usage) => handleChange(usage, index)}
          />
        ))}
      </InputsWrapper>
      <CTA onClick={handleClick} />
      <ItemsWrapper>
        <ItemsHeader />

        {map(usages, (usage, index) => (
          <UsageItem
            usage={usage}
            key={`usageItem-${index}`}
            onChange={(usage) => handleChange(usage, index)}
            onDelete={size(usages) > 1 ? () => handleDelete(index) : undefined}
          />
        ))}
      </ItemsWrapper>
    </>
  );
};
