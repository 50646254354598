import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { startCase } from "lodash";
import {
  useFeatures,
  useStoreModel,
  useUserKindContext,
  useVerticalConfigurations,
} from "src/hooks";
import {
  AssetFileUploadInput,
  AssetKind,
  TalentVertical,
} from "src/graphql/types";
import {
  initialUserValues,
  UserValidationSchema,
} from "src/components/dashboards/agents/talentManagement/forms/formConfigV2";
import { useCTSteps } from "src/components/dashboards/agents/talentManagement/add/useCTSteps";
import { FormLayoutHeader, Loading, PopupNotification } from "src/components";
import { FormLayoutActionBar } from "src/components/formLayout";
import { Box, Grid, FormLayout, Flex } from "src/ccl/layout";
import { Text } from "src/ccl/document";
import {
  AgentDashboardLayout,
  ProfileForm,
} from "src/components/dashboards/agents";
import { getConfigForVertical, verticalMap } from "src/utils/user";
import { ValidationBlock } from "src/ccl/feedback";
import { FileDragDropUpload } from "src/ccl/data-entry";
import { sendToAmplitude } from "src/utils/analytics";
import { toSentence } from "src/utils/lang";
import { getValidationErrors } from "src/validations";

interface LegacyProfileProps {
  vertical: TalentVertical;
}

export const LegacyProfile = ({ vertical }: LegacyProfileProps) => {
  const draftTalent = useStoreModel("draftTalent");
  const history = useHistory();
  const steps = useCTSteps();
  const { pathname } = useLocation();
  const currentUser = useStoreModel("currentUser");
  const userKindContext = useUserKindContext(currentUser);
  const agencyName = currentUser.me?.agency?.name;

  const { featureEnabled } = useFeatures();

  const prettyVertical = verticalMap[vertical];

  useEffect(() => {
    sendToAmplitude("adds creative profile - lands on page", {
      url: pathname,
      userType: userKindContext,
      agency: agencyName,
    });
  }, []);

  const [showPopupNotification, setShowPopupNotification] = useState(false);

  const {
    loading: verticalConfigLoading,
    error: verticalConfigError,
    configurations: verticalConfigs,
  } = useVerticalConfigurations();

  if (verticalConfigLoading) {
    return <Loading />;
  }
  const verticalConfig = getConfigForVertical(verticalConfigs || [], vertical);

  if (verticalConfigError || !verticalConfigs || !verticalConfig) {
    return <Text variant="h4">Oops, something went wrong</Text>;
  }

  const initialValues =
    draftTalent.details ||
    initialUserValues(
      verticalConfig,
      featureEnabled("auto_hide_tpa_talent")
        ? currentUser.isFirstPartyAgent
        : true,
    );

  if (draftTalent?.shrineAssets) {
    initialValues.talentProfile.shrineAssets = [
      draftTalent.shrineAssets as AssetFileUploadInput,
    ];
  }

  return (
    <AgentDashboardLayout>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const { talentProfile } = values;
          if (!talentProfile.gender) {
            return;
          }
          draftTalent.setDetails({
            ...values,
            name: `${values.firstName} ${values.lastName}`,
            talentProfile: {
              ...talentProfile,
              vertical: vertical,
              isContactLaunch: false,
              minDayRate: talentProfile.minDayRate
                ? talentProfile.minDayRate
                : undefined,
              maxDayRate: talentProfile.maxDayRate
                ? talentProfile.maxDayRate
                : undefined,
            },
          });
          history &&
            history.push(
              `/dashboard/agent/talent/new/portfolio?v=${prettyVertical}s`,
            );
        }}
        validate={async (values) => {
          const schema = UserValidationSchema(
            verticalConfig,
            values.talentProfile.gender,
            "create",
          );

          return getValidationErrors(values, schema);
        }}
        validateOnBlur={true}
        validateOnChange={false}
        validateOnMount={false}
      >
        {({ values, submitForm, errors, setFieldValue, submitCount }) => {
          const errorFieldNames = Object.keys(errors).map((errorName) =>
            startCase(errorName).toLowerCase(),
          );
          return (
            <FormLayout
              wrapperComponent={React.Fragment}
              header={
                <FormLayoutHeader
                  title={`Add ${prettyVertical}`}
                  ctaText="Continue"
                  goBackTo={`/dashboard/agent/talent?v=${prettyVertical}s`}
                  currentStepIndex={0}
                  onClick={() => {
                    sendToAmplitude("adds creative profile - clicks continue", {
                      url: pathname,
                      userType: userKindContext,
                      agency: agencyName,
                    });
                    submitForm();
                    if (errors) {
                      setShowPopupNotification(true);
                      setTimeout(() => {
                        setShowPopupNotification(false);
                      }, 6000);
                    }
                  }}
                  steps={steps}
                />
              }
              actionBar={
                <FormLayoutActionBar
                  title={`Add ${prettyVertical}`}
                  onClick={() => {
                    submitForm();
                    if (submitCount > 0 && errors) {
                      setShowPopupNotification(true);
                      setTimeout(() => {
                        setShowPopupNotification(false);
                      }, 6000);
                    }
                  }}
                  ctaText="Continue"
                  goBackTo={`/dashboard/agent/talent?v=${prettyVertical}s`}
                  currentStepIndex={0}
                  steps={steps}
                />
              }
              actionBarCSS={{ zIndex: 0 }}
            >
              {showPopupNotification && (
                <PopupNotification
                  showClose={true}
                  onClose={() => {
                    setShowPopupNotification(false);
                  }}
                >
                  <Flex
                    css={{ gap: "$4", alignItems: "center", height: "100%" }}
                  >
                    <Box
                      css={{
                        width: "3px",
                        minHeight: "22px",
                        backgroundColor: "red",
                        borderRadius: "4px",
                      }}
                    />
                    <Text color="black" weight="bold">
                      The {toSentence(errorFieldNames)}{" "}
                      {errorFieldNames.length === 1
                        ? "field needs "
                        : "fields need "}{" "}
                      attention
                    </Text>
                  </Flex>
                </PopupNotification>
              )}
              <Grid
                css={{
                  gridGap: "$9",
                  gridColumns: 1,
                  alignItems: "flex-start",
                  mt: "$11",
                  "@bp2": {
                    mt: "$16",
                  },
                  "@bp4": {
                    gridColumns: "1fr 2fr",
                    mt: "$17",
                  },
                }}
              >
                <Box
                  data-test-id="ProfileImage"
                  css={{
                    "@bp2": {
                      maxWidth: "50%",
                    },
                    "@bp4": {
                      maxWidth: "100%",
                    },
                  }}
                >
                  <Text variant="h3" css={{ mb: "$9" }}>
                    Profile Image
                  </Text>
                  <FileDragDropUpload
                    type="image"
                    multiple={false}
                    contentHovering={<Text>Drop profile photo here...</Text>}
                    contentNoFiles={
                      <Box css={{ width: "90%", p: "$4" }}>
                        <Text css={{ textDecoration: "underline" }}>
                          Upload a profile photo
                        </Text>
                        <Text variant="meta" css={{ color: "$grey6" }}>
                          Or drag a file here
                        </Text>
                      </Box>
                    }
                    placeholderVariant="grey"
                    containerCss={{ textAlign: "left", height: "auto" }}
                    contentWithFiles={
                      <Box css={{ width: "90%", p: "$4" }}>
                        <Text
                          css={{
                            textDecoration: "underline",
                            textAlign: "center",
                          }}
                        >
                          Change profile photo
                        </Text>
                      </Box>
                    }
                    isReplaceable
                    attachmentStoreName="uploads"
                    maxFileCount={1}
                    onUpload={([uploadedFile]) => {
                      if (!uploadedFile?.mediaUrl) {
                        return;
                      }
                      draftTalent.setFeaturedAsset({
                        kind: AssetKind.Featured,
                        mediaUrl: uploadedFile.mediaUrl,
                      });
                      delete uploadedFile.mediaUrl;
                      draftTalent.setShrineAssets({
                        kind: AssetKind.Featured,
                        file: uploadedFile,
                      });
                      setFieldValue("talentProfile.shrineAssets", [
                        {
                          kind: "FEATURED",
                          file: uploadedFile,
                        },
                      ]);
                    }}
                    existingImage={
                      (draftTalent?.featuredAsset && {
                        id: "1",
                        sortWeight: 0,
                        ...draftTalent.featuredAsset,
                      }) ||
                      undefined
                    }
                    showPreviewImage
                    altText="Profile image"
                    showPreviewList={false}
                    showPlaceholder={false}
                    withBorder
                    showMaxFileCountReached={false}
                    imageCss={{ width: 150, mr: "$6" }}
                  />

                  {errors.talentProfile?.shrineAssets && (
                    <ValidationBlock
                      variant="error"
                      title={errors.talentProfile?.shrineAssets}
                      css={{ mt: "$3" }}
                    />
                  )}
                </Box>

                <ProfileForm
                  values={values}
                  vertical={vertical}
                  verticalConfig={verticalConfig}
                  context="create"
                />
              </Grid>
            </FormLayout>
          );
        }}
      </Formik>
    </AgentDashboardLayout>
  );
};
