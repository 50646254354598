import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { styled } from "src/ccl";
import { Box, Flex } from "src/ccl/layout";
import { Icon, InfoBanner, Text } from "src/ccl/document";
import { useFeatures, useStoreModel } from "src/hooks";
import { Button, Link } from "src/ccl/navigation";
import { ShortlistVerticals } from "src/components/ShortlistVerticals";
import { getTalentVerticals } from "src/utils/user";
import { pluralize } from "src/utils/lang";
import { DatePicker } from "src/ccl/data-entry";
import { User } from "src/graphql/types";
import { ShareShortlistModal } from "src/components/ShareShortlistModal";
import { formatCurrency } from "src/utils/currencyFormatter";
import { jobPriceForModels } from "src/utils/job";
import { commissionMultiplier } from "src/config";

const Backdrop = styled("div", {
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: "$999",
  transition: "400ms ease all",
  opacity: 0,
  visibility: "hidden",

  variants: {
    state: {
      open: {
        opacity: 1,
        visibility: "visible",
      },
    },
  },
});

const Wrapper = styled("div", {
  position: "fixed",
  top: "$0",
  bottom: "$0",
  right: "$0",
  backgroundColor: "$white",
  zIndex: "$999",
  boxShadow: "0px 0px 100px rgba(0, 0, 0, 0.15)",
  minWidth: "312px",
  maxWidth: "370px",
  boxSizing: "border-box",
  overflowY: "auto",
  transition: "400ms ease all",
  transform: "translate(calc(100% + 40px), 0)",
  visibility: "hidden",

  "@bp4": { top: "$5", bottom: "$5", right: "$4", borderRadius: "8px" },

  variants: {
    state: {
      open: {
        transform: "translate(0, 0)",
        visibility: "visible",
      },
    },
  },
});

const EmptyState = () => (
  <Box css={{ textAlign: "center", mt: "$11" }}>
    <Icon variant="starSearch" size={80} color="grey3" />
    <Text color="grey6">
      Add talents to build your shortlist. You can also create a job and add
      talents later.
    </Text>
  </Box>
);

export interface ShortlistTrayProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void;
  talents?: User[]; // Add this prop to receive talents
  isPackage?: boolean;
  initialStartDate?: Date;
}

export const ShortlistTray = ({
  isPackage = false,
  open,
  onClose,
  onContinue,
  talents,
  initialStartDate,
}: ShortlistTrayProps) => {
  const {
    draftV2: {
      talentUsers,
      removeTalentUser,
      setStartDate,
      setEndDate,
      startDate,
    },
  } = useStoreModel("jobs");
  const { removeTalent } = useStoreModel("package");
  const { draftV2 } = useStoreModel("jobs");
  const history = useHistory();
  const { pathname } = useLocation();
  const currentUser = useStoreModel("currentUser");
  const { jobVerticals } = draftV2;
  const { featureEnabled } = useFeatures();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const talentList = talents ? talents : talentUsers;
  const talentVerticals = getTalentVerticals(talentList);
  const numberOfTalent = talentList.length;

  const isLoggedIn = currentUser?.loggedIn;
  const isJobDetailsPage = pathname === "/jobs/new/details";

  const totalFee = jobVerticals.reduce(
    (acc, cur) => acc + jobPriceForModels(cur.budget, cur.talentRequired),
    0,
  );

  const bookingFee = totalFee * commissionMultiplier;
  const vatFee = (totalFee + bookingFee) * commissionMultiplier;

  useEffect(() => {
    if (open) {
      const isStartDateInPast = startDate < new Date();
      setStartDate(isStartDateInPast ? new Date() : startDate);
      setEndDate(null);
    }

    if (initialStartDate) {
      setStartDate(initialStartDate);
    }
  }, [open, setEndDate]);

  return (
    <Box data-test-id="ShortlistTray">
      <Backdrop state={open ? "open" : undefined} onClick={onClose} />
      <Wrapper state={open ? "open" : undefined}>
        <Flex
          css={{ py: "$11", px: "$8", height: "100%", flexDirection: "column" }}
        >
          <Flex
            css={{
              pb: "30px",
              height: "54px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text variant="nh3">Shortlist</Text>
            {isPackage && (
              <Button
                variant="simplified"
                css={{
                  textDecoration: "none",
                  backgroundColor: "transparent",
                  fontFamily: "$sansNew",
                  textTransform: "uppercase",
                  fontSize: "$12",
                  fontWeight: "$bold",
                }}
                onClick={() => setIsShareModalOpen(true)}
              >
                <Icon variant="share" size={18} />
                Share Shortlist
              </Button>
            )}
            <Box data-test-id="ShortlistTrayClose" onClick={onClose}>
              <Icon size={18} variant="cross" />
            </Box>
          </Flex>

          <Box
            css={{
              overflowY: "scroll",
              overflowX: "hidden",
              borderBottom: "1px solid $grey2",
              pb: "$5",
              flex: 1,
            }}
          >
            {numberOfTalent === 0 ? (
              <EmptyState />
            ) : (
              <ShortlistVerticals
                talent={talentList}
                talentVerticals={talentVerticals}
                isRemovable={(u) =>
                  !!talentList.map((t) => t.id).includes(u.id)
                }
                onRemove={(u) => {
                  if (isPackage) {
                    removeTalent(u.id);
                  } else {
                    removeTalentUser(u.id);
                  }
                }}
                withinShortlistButton={true}
                removeText="Remove"
                avatarSizes={{
                  width: 72,
                  height: 90,
                }}
              />
            )}
          </Box>

          <>
            {isJobDetailsPage ? (
              <>
                <Flex css={{ gap: "$7", flexDirection: "column", pt: "$7" }}>
                  <Flex css={{ justifyContent: "space-between" }}>
                    <Text>Total creative fees</Text>
                    <Text>{formatCurrency(totalFee)}</Text>
                  </Flex>
                  <Flex css={{ justifyContent: "space-between" }}>
                    <Text>Booking fees</Text>
                    <Text>{formatCurrency(bookingFee)}</Text>
                  </Flex>
                  <Flex css={{ justifyContent: "space-between" }}>
                    <Text>VAT</Text>
                    <Text>{formatCurrency(vatFee)}</Text>
                  </Flex>
                  <Flex css={{ pt: "$7", alignItems: "center" }}>
                    <Icon size={18} variant="alert" />
                    <Text weight="bold" css={{ ml: "$3" }}>
                      Total fee estimate
                    </Text>
                    <Text weight="bold" css={{ ml: "auto" }}>
                      {formatCurrency(bookingFee + vatFee + totalFee)}
                    </Text>
                  </Flex>
                </Flex>
              </>
            ) : (
              <>
                {isLoggedIn && (
                  <Box
                    css={{
                      display: "none",
                      "@bp4": {
                        display: "initial",
                      },
                    }}
                  >
                    <Text
                      variant="b2Bold"
                      css={{
                        mb: "$5",
                        mt: "$6",
                      }}
                    >
                      When&apos;s the job?
                    </Text>
                    <DatePicker
                      disablePastDates
                      onChange={(date) => {
                        const userDateMidnight = new Date(
                          date.setHours(0, 0, 0, 0),
                        );
                        const utcDate = new Date(
                          Date.UTC(
                            userDateMidnight.getFullYear(),
                            userDateMidnight.getMonth(),
                            userDateMidnight.getDate(),
                          ),
                        );
                        setStartDate(utcDate);
                        setEndDate(utcDate);
                      }}
                      initialDate={startDate || new Date()}
                    />
                  </Box>
                )}
                <Text weight="bold" css={{ mt: "$6", mb: "$4" }}>
                  {numberOfTalent === 0
                    ? "No talents "
                    : `${pluralize(numberOfTalent, "Talent")} `}
                  shortlisted
                </Text>
                <Text
                  variant="meta"
                  color="grey6"
                  css={{
                    mb: "$9",
                  }}
                >
                  {numberOfTalent === 0
                    ? "Start adding talents in the Marketplace to your shortlist."
                    : "We recommend shortlisting double the amount of talents you need"}
                </Text>

                {isLoggedIn ? (
                  <Flex
                    css={{
                      flexDirection: "column",
                      gap: "14px",
                    }}
                  >
                    {numberOfTalent > 0 && (
                      <Button
                        type="submit"
                        variant="primaryCta"
                        onClick={onContinue}
                      >
                        Create Job
                      </Button>
                    )}

                    {![
                      "/jobs/new/shortlist",
                      "/dashboard/client/talent/marketplace",
                      "/dashboard/client/talent/select",
                      "/dashboard/client/talent/open",
                    ].includes(pathname) && (
                      <Button
                        variant="secondaryCta"
                        onClick={() =>
                          currentUser.loggedIn &&
                          featureEnabled("unified_dashboards")
                            ? history.push(
                                "/dashboard/client/talent/marketplace",
                              )
                            : history.push("/jobs/new/shortlist")
                        }
                      >
                        Add to shortlist
                      </Button>
                    )}
                  </Flex>
                ) : isPackage ? (
                  <Flex
                    css={{
                      flexDirection: "column",
                      gap: "14px",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="primaryCta"
                      onClick={() => history.push("/jobs/new/sign-in")}
                    >
                      Sign In to continue
                    </Button>
                    <Flex css={{ justifyContent: "center" }}>
                      <InfoBanner
                        withIcon={false}
                        css={{
                          alignItems: "center",
                        }}
                      >
                        <Text
                          css={{
                            color: "$grey6",
                            fontFamily: "$sansNew",
                            fontSize: "$14",
                            fontWeight: "$medium",
                            lineHeight: "19px",
                            letterSpacing: "0.02em",
                            textAlign: "center",
                          }}
                        >
                          You&apos;ll need to sign in to your account to confirm
                          this package.
                        </Text>
                      </InfoBanner>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex css={{ flexDirection: "column", gap: "14px" }}>
                    <Button
                      type="submit"
                      variant="primaryCta"
                      onClick={() => history.push("/jobs/new/sign-in")}
                    >
                      Sign In to continue
                    </Button>
                    <Flex css={{ justifyContent: "center" }}>
                      <InfoBanner
                        css={{
                          alignItems: "center",
                        }}
                      >
                        <Text
                          css={{
                            color: "$grey6",
                            fontFamily: "$sansNew",
                            fontSize: "$14",
                            fontWeight: "$medium",
                            lineHeight: "19px",
                            letterSpacing: "0.02em",
                          }}
                        >
                          New here?{" "}
                          <Link
                            to="/jobs/new/sign-up"
                            css={{
                              color: "$grey6",
                            }}
                          >
                            Create an account here.
                          </Link>
                        </Text>
                      </InfoBanner>
                    </Flex>
                  </Flex>
                )}
              </>
            )}
          </>
        </Flex>
      </Wrapper>
      <ShareShortlistModal
        talent={talentList}
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        talentVerticals={talentVerticals}
      />
    </Box>
  );
};
