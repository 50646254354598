import React from "react";
import * as Stitches from "@stitches/react";
import {
  TalentProfile,
  TalentVertical,
  User,
} from "src/graphql/generated/types";
import { Box, Flex } from "src/ccl/layout";
import { isVerticalMember, verticalIconMap, verticalMap } from "src/utils/user";
import {
  Icon,
  TalentListItem,
  TalentListItemProps,
  Text,
} from "src/ccl/document";
import { ExternalLink, Link } from "src/ccl/navigation";
import { mergeCss } from "src/ccl";
import { useFeatures } from "src/hooks";
import { formatCurrency } from "src/utils/currencyFormatter";

const AddMore = ({ link }: { link: string }) => (
  <Link to={link}>
    <Flex css={{ alignItems: "center", gap: "$3", py: "auto" }}>
      <Box
        css={{
          backgroundColor: "$grey3",
          borderRadius: "$round",
          width: "20px",
          height: "20px",
        }}
      >
        <Icon variant="plus" size={10} css={{ mb: "1px", ml: "5px" }} />
      </Box>
      Add more
    </Flex>
  </Link>
);

const EmailAll = ({ link }: { link: string }) => (
  <ExternalLink to={link}>
    <Flex css={{ alignItems: "center", gap: "$3", py: "auto" }}>
      <Icon variant="envelope" size={14} />
      Email all
    </Flex>
  </ExternalLink>
);

interface ShortlistVerticalsProps extends Omit<TalentListItemProps, "talent"> {
  talent: User[];
  talentVerticals: TalentVertical[];
  withAddMore?: boolean;
  withEmailAll?: boolean;
  jobSlug?: string;
  titleCss?: Stitches.CSS;
  withinShortlistButton?: boolean;
  removeText?: string;
  ccEmail?: string;
  initialTalentUsers?: string[];
}

export const ShortlistVerticals = ({
  talent,
  talentVerticals,
  isRemovable,
  onRemove,
  getSubHeading,
  withAddMore,
  withEmailAll,
  jobSlug,
  titleCss = {},
  withinShortlistButton = false,
  removeText,
  ccEmail,
  initialTalentUsers = [],
  ...talentListItemProps
}: ShortlistVerticalsProps) => {
  const { featureEnabled } = useFeatures();

  return (
    <>
      {featureEnabled("job_details_revamp") && withinShortlistButton ? (
        <>
          {talent.map((u) => (
            <TalentListItem
              showVertical
              showTalentDescriptorText={false}
              removeText={removeText}
              key={u.id}
              talent={u}
              isRemovable={isRemovable}
              onRemove={onRemove}
              openInNewWindow
              avatarCss={{
                borderRadius: "8px",
              }}
              getSubHeading={(talent) => {
                const profile = talent.profile as TalentProfile;
                const elements = [];

                if (profile.pronouns) {
                  elements.push(<Text key="pronouns">{profile.pronouns}</Text>);
                }

                if (profile.location) {
                  elements.push(
                    <Flex
                      key="location"
                      css={{ alignItems: "center", gap: "$1" }}
                    >
                      <Icon color="grey5" variant="location" size={18} />
                      <Text css={{ fontSize: 14, color: "$grey5" }}>
                        {profile.location}
                      </Text>
                    </Flex>,
                  );
                }

                if (profile.maxDayRate) {
                  elements.push(
                    <Text key="rate">
                      {formatCurrency(profile.maxDayRate, "gbp")}
                    </Text>,
                  );
                }

                return (
                  <Flex css={{ alignItems: "center", gap: "$2" }}>
                    {elements.map((element, i) => (
                      <React.Fragment key={i}>
                        {element}
                        {i < elements.length - 1 && <Text>•</Text>}
                      </React.Fragment>
                    ))}
                  </Flex>
                );
              }}
              ccEmail={ccEmail}
              isHighlighted={
                initialTalentUsers.length > 0 &&
                initialTalentUsers.filter((id) => id === u.id).length === 0
              }
              {...talentListItemProps}
            />
          ))}
        </>
      ) : (
        <>
          {talentVerticals.map((vertical, i) => {
            const verticalTalent = talent.filter((u) =>
              isVerticalMember(vertical, u),
            );

            return (
              <Box
                key={i}
                css={{
                  py: "$5",
                  borderBottom: withinShortlistButton
                    ? undefined
                    : "1px solid $grey3",
                  "&:last-child": {
                    borderBottom: "none",
                    pb: "$0",
                  },
                }}
              >
                <Flex
                  css={{
                    flexDirection: withinShortlistButton ? "row" : "column",
                    justifyContent: withinShortlistButton
                      ? undefined
                      : "space-between",
                    pb: withinShortlistButton ? "10px" : "$5",
                    mb: withinShortlistButton ? "$4" : undefined,
                    borderBottom: withinShortlistButton
                      ? "1px solid $grey2"
                      : undefined,
                    gap: "$4",
                    "@bp2": { flexDirection: "row", borderBottom: "none" },
                  }}
                >
                  <Box
                    css={{
                      display: withAddMore ? "block" : "none",
                      "@bp4": {
                        display: "none",
                      },
                    }}
                  >
                    <Icon variant={verticalIconMap[vertical]} />
                  </Box>
                  <Text
                    variant="buttonText"
                    css={mergeCss(
                      { fontWeight: titleCss.fontWeight || "$bold" },
                      titleCss,
                    )}
                  >
                    {`${verticalMap[vertical]}s (${verticalTalent.length})`}
                  </Text>
                  {withAddMore && (
                    <AddMore
                      link={
                        jobSlug
                          ? `/dashboard/client/jobs/${jobSlug}/talent?v=${vertical.toLowerCase()}`
                          : `/jobs/new/shortlist?v=${vertical.toLowerCase()}`
                      }
                    />
                  )}
                  {withEmailAll && (
                    <EmailAll
                      link={`mailto:?bcc=${verticalTalent
                        .map((t) => t.email)
                        .join(",")}`}
                    />
                  )}
                </Flex>
                {verticalTalent.map((u) => (
                  <TalentListItem
                    showVertical
                    showTalentDescriptorText={false}
                    removeText={removeText}
                    key={u.id}
                    talent={u}
                    isRemovable={isRemovable}
                    onRemove={onRemove}
                    getSubHeading={getSubHeading}
                    openInNewWindow
                    avatarCss={{
                      borderRadius: "8px",
                    }}
                    ccEmail={ccEmail}
                    {...talentListItemProps}
                  />
                ))}
              </Box>
            );
          })}
        </>
      )}
    </>
  );
};
