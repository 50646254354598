import React from "react";
import type * as Stitches from "@stitches/react";
import { capitalize } from "lodash";
import { Box, Flex, Grid } from "src/ccl/layout";
import { Icon, Text } from "src/ccl/document";
import { useStoreModel } from "src/hooks";
import { Toggle } from "src/ccl/data-entry";
import {
  NumericAttribute,
  TalentProfile,
  User,
  ConversionType,
} from "src/graphql/types";
import { formatMetricV2 } from "src/utils/measurementConversions";

type Unit = "metric" | "imperial";
const attributeBoxesPerRow = 5;

const renderMeasurement = (attribute: NumericAttribute, unit: Unit) => {
  const { name, value, conversionType } = attribute;
  if (unit === "metric") {
    if (name.includes("shoe")) {
      return (
        <Text variant="modalB2">
          {formatMetricV2(value as number, ConversionType.EuShoe)}
        </Text>
      );
    }
    return (
      <Text variant="modalB2">
        {formatMetricV2(value as number, ConversionType.Centimeters)}
      </Text>
    );
  }

  if (unit === "imperial") {
    return (
      <Text variant="modalB2">
        {formatMetricV2(value as number, conversionType)}
      </Text>
    );
  }
};

const AttributeBox = ({
  attribute,
  index,
  unit,
}: {
  attribute: NumericAttribute;
  index: number;
  unit: "metric" | "imperial";
}) => (
  <Box
    css={{
      padding: "$3",
      borderRight: "1px solid $grey3",
      borderLeft: index % attributeBoxesPerRow === 0 ? "1px solid $grey3" : "",
    }}
  >
    <Text
      variant="modalB2"
      css={{
        fontWeight: "$bold",
      }}
    >
      {capitalize(attribute.name)}
    </Text>
    {renderMeasurement(attribute, unit)}
  </Box>
);

interface TalentProfileMeasurementsProps {
  talent: User;
  css?: Stitches.CSS;
}

export const TalentProfileMeasurements = ({
  talent,
}: TalentProfileMeasurementsProps) => {
  const { measurementUnit } = useStoreModel("preferences");
  const attributes = (talent.profile as TalentProfile).numericAttributes
    ?.filter((a) => !!a.value)
    .map((a) => ({ ...a, name: a.name.split("_")[0] } as NumericAttribute));

  return (
    <Box css={{ mt: "$10" }}>
      <Flex
        css={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Flex css={{ gap: "$3", alignItems: "center" }}>
          <Icon variant="tapeMeasure" size={18} />
          <Text color="grey5" css={{ fontWeight: "$bold" }}>
            Measurements
          </Text>
        </Flex>
        <Toggle
          width={41}
          height={24}
          id="measurementsToggle"
          textUnchecked="Metric"
          textChecked="Imperial"
          checked={measurementUnit.preference === "imperial"}
          onCheck={() => {
            measurementUnit.setPreference("imperial");
          }}
          onUncheck={() => {
            measurementUnit.setPreference("metric");
          }}
          uncheckedGreyBackground
        />
      </Flex>
      <Grid
        css={{
          gridColumns: attributeBoxesPerRow,
          mt: "$5",
        }}
      >
        {attributes &&
          attributes.map((attr, i) => (
            <AttributeBox
              attribute={attr}
              key={i}
              index={i}
              unit={measurementUnit.preference}
            />
          ))}
      </Grid>
    </Box>
  );
};
