export class EnvironmentVariableError extends Error {}

export class MalformedEnvironmentVariableError extends EnvironmentVariableError {
  constructor(variableName: string) {
    super(
      `Invalid environment variable ${variableName} - name must have REACT_APP_ prefix`,
    );
    this.name = "MalformedEnvironmentVariableError";
  }
}

export class MissingEnvironmentVariableError extends EnvironmentVariableError {
  constructor(variableName: string) {
    super(`Missing environment variable ${variableName}`);
    this.name = "MissingEnvironmentVariableError";
  }
}

// See https://create-react-app.dev/docs/adding-custom-environment-variables/
const whitelistEnvvars = ["NODE_ENV", "PUBLIC_URL"];

function canAccess(name: string): boolean {
  return whitelistEnvvars.includes(name) || name.startsWith("REACT_APP_");
}

// sometimes we want variables in dev only, and don't want to have to add them to
// our hosting platform / ci / whatever
const devEnvironmentVariable = (environmentVariableName: string) => {
  if (!isDev) {
    return;
  }
  if (!canAccess(environmentVariableName)) {
    throw new MalformedEnvironmentVariableError(environmentVariableName);
  }

  return process.env[environmentVariableName];
};

const envvarOrDevDefault = (name: string, devValue: string): string => {
  if (!canAccess(name)) {
    throw new MalformedEnvironmentVariableError(name);
  }

  if (name in process.env) {
    return process.env[name]!;
  }

  if (isDev || isTest) {
    return devValue;
  }

  throw new MissingEnvironmentVariableError(name);
};

// utility
export const isDev = process.env.NODE_ENV === "development";
export const isTest = process.env.NODE_ENV === "test";
export const isProd = process.env.NODE_ENV === "production";

export const environmentName = envvarOrDevDefault(
  "REACT_APP_ENVIRONMENT",
  "development",
);

export const s3MediaUrl =
  "https://contact-media.s3-eu-west-1.amazonaws.com/gloss";

const platformBaseUrl = envvarOrDevDefault(
  "REACT_APP_PLATFORM_BASE_URL",
  "https://api.contact-staging.com",
);

export const graphQLBaseUrl = `${platformBaseUrl}/graphql`;
export const adminDashboardUrl = `${platformBaseUrl}/admin`;
export const webSocketBaseUrl = `${isProd ? "wss" : "wss"}://${
  new URL(platformBaseUrl).host
}/cable`;
// contentful
export const contentfulSpaceId = envvarOrDevDefault(
  "REACT_APP_CONTENTFUL_SPACE_ID",
  "l81brv70t2rv",
);
export const contentfulAccessToken = envvarOrDevDefault(
  "REACT_APP_CONTENTFUL_ACCESS_TOKEN",
  "qtQ_UsFytcKN5SmmGzgCtrIITB2OgBI04rDmS1AQg1Q",
);
export const contentfulPreviewAccessToken = envvarOrDevDefault(
  "REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN",
  "lkYredchNt1cn2d-MwZwJkLjeBsrNTObE4iRgfMnlxY",
);
export const disableEnvironmentBanner =
  process.env.REACT_APP_DISABLE_ENVIRONMENT_BANNER;

// google maps
export const googleMapsApiKey = envvarOrDevDefault(
  "REACT_APP_GOOGLE_MAPS_API_KEY",
  "AIzaSyDlJ548ar185iUe1blOrBxgmTvZiuvwWZA",
);

export const bugsnagApiKey = envvarOrDevDefault(
  "REACT_APP_BUGSNAG_API_KEY",
  "4c9876c019c0e2817d9ef4d66963a2a8",
);

export const amplitudeApiKey = envvarOrDevDefault(
  "REACT_APP_AMPLITUDE_API_KEY",
  "9af63d198596dc0b1bd033706d9246fb",
);

export const builderApiKey = envvarOrDevDefault(
  "REACT_APP_BUILDER_API_KEY",
  "3a4a90a3b35c4b44a923b538217c5c34",
);

export const mediaProvider = envvarOrDevDefault(
  "REACT_APP_MEDIA_PROVIDER",
  "imgix",
);

export const twicpicsUrl = envvarOrDevDefault(
  "REACT_APP_TWICPICS_BASE_URL",
  "",
);

export const trustshareApiKey = envvarOrDevDefault(
  "REACT_APP_TRUSTSHARE_PUBLIC_KEY",
  "sandbox_pk_jWDV2fOuCvWOOHsL5Db0qDxpAZe2D2fL",
);

export const mixpanelApiKey = envvarOrDevDefault(
  "REACT_APP_MIXPANEL_API_KEY",
  "mixpanel",
);

export const debugAnalytics = devEnvironmentVariable(
  "REACT_APP_DEBUG_ANALYTICS",
);

export const baseUrl = envvarOrDevDefault("PUBLIC_URL", "");

export const commissionMultiplier = 0.2;
export const vatRate = 0.2;

export const MaxDeckFileSizeMb = 25;
export const MaxNumberOfDeckFilesPerJob = 5;

// A booker can only shortlist this many additional talent after their job is approved
export const additionalPostApprovalShortlistCount = 10;

// firebase env config
export const firebaseApiKey = envvarOrDevDefault(
  "REACT_APP_FIREBASE_API_KEY",
  "",
);

export const firebaseAuthDomain = envvarOrDevDefault(
  "REACT_APP_FIREBASE_AUTH_DOMAIN",
  "",
);

export const firebaseProjectionId = envvarOrDevDefault(
  "REACT_APP_FIREBASE_PROJECT_ID",
  "",
);

export const firebaseStorageBucket = envvarOrDevDefault(
  "REACT_APP_FIREBASE_STORAGE_BUCKET",
  "",
);

export const firebaseMsgSenderId = envvarOrDevDefault(
  "REACT_APP_FIREBASE_MESSAGING_SENDER_ID",
  "",
);

export const firebaseAppId = envvarOrDevDefault(
  "REACT_APP_FIREBASE_APP_ID",
  "",
);

export const firebaseMeasurementId = envvarOrDevDefault(
  "REACT_APP_FIREBASE_MEASUREMENT_ID",
  "",
);

export const firebaseConfig = {
  apiKey: firebaseApiKey,
  authDomain: firebaseAuthDomain,
  projectId: firebaseProjectionId,
  storageBucket: firebaseStorageBucket,
  messagingSenderId: firebaseMsgSenderId,
  appId: firebaseAppId,
  measurementId: firebaseMeasurementId,
};
