import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";
import {
  AssetFileUploadInput,
  DataType,
  Gender,
  Maybe,
  PortfolioImageInput,
  Pronouns,
  TalentLocation,
  TalentVertical,
  VerticalConfiguration,
} from "src/graphql/types";

const baseProfileValidations = {
  website: Yup.string()
    .nullable()
    .trim()
    .matches(/([a-z])+\.([a-z])+/, "Must be a valid website"),
  gender: Yup.string().required("Required").nullable(),
  dateOfBirth: Yup.string()
    .required("Required")
    .test("validDob", "Must be in dd/mm/yyyy format", (value) => {
      const segments = (value || "").toString().split("/");

      if (segments.length !== 3) {
        return false;
      }

      const [day, month, year] = segments.map((s: string) => parseInt(s));

      if (isNaN(day) || isNaN(month) || isNaN(year)) {
        return false;
      }

      if (day <= 0 || month <= 0 || year <= 1900) {
        return false;
      }

      if (day > 31 || month > 12) {
        return false;
      }

      return true;
    }),
  primaryLocation: Yup.object().shape({
    name: Yup.string().required("Required").trim(),
  }),
  biography: Yup.string()
    .required("Required")
    .matches(/^\W*(?:\w+\b\W*){3,}$/, "At least 3 words please")
    .trim(),
  minDayRate: Yup.number()
    .typeError("Must be a number")
    .min(0, "Must be more than 0")
    .nullable(),
  maxDayRate: Yup.number()
    .typeError("Must be a number")
    .min(0, "Must be more than 0")
    .max(5000, "Can't be more than £5000")
    .nullable(),
  socialMediaFields: Yup.array().of(
    Yup.object().shape({
      platform: Yup.string().trim().required("Required"),
      handle: Yup.string().trim().required("Required"),
      followerCount: Yup.number()
        .typeError("Must be a number")
        .integer("Must be a whole number")
        .positive("Must be a positive number")
        .required("Required"),
    }),
  ),
};

const updateProfileValidations = {
  ...baseProfileValidations,
  shrineAssets: Yup.array(),
};

const createProfileValidations = {
  ...baseProfileValidations,
  shrineAssets: Yup.array().required().min(1, "Must have a profile photo"),
};

export const UserValidationSchema = (
  verticalConfig: VerticalConfiguration,
  gender: Gender | null | undefined,
  context: "create" | "update",
) => {
  const userFields = {
    firstName: Yup.string().required("Required").trim(),
    lastName: Yup.string().required("Required").trim(),
    email: Yup.string()
      .required("Required")
      .email("Must be an email address")
      .trim(),
    phoneNumber: Yup.string().required("Required").trim(),
    addressLine1: Yup.string().required("Required").trim(),
    city: Yup.string().required("Required").trim(),
    postcode: Yup.string().required("Required").trim(),
    country: Yup.string().required("Required").trim(),
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const verticalDataValidationSchema: ObjectShape = {};
  verticalConfig.fields.forEach((field) => {
    const requiredForAll = !!field.required;
    const requiredForGender = !!(
      gender &&
      field.requiredGenders &&
      field.requiredGenders.includes(gender.toUpperCase() as Gender)
    );

    const required = requiredForAll || requiredForGender;

    let validation;

    if (field.dataType === DataType.Integer) {
      validation = Yup.number().typeError("Must be a number");
    } else {
      if (field.multiple) {
        validation = Yup.array();
      } else {
        validation = Yup.string();
      }
    }

    if (required) {
      validation = validation.required("Required");
    }

    const unit = field.unit || "";
    if (field.minValue) {
      validation = validation.min(
        field.minValue,
        `Must be at least ${field.minValue}${unit}`,
      );
    }
    if (field.maxValue) {
      validation = validation.max(
        field.maxValue,
        `Can't be more than ${field.maxValue}${unit}`,
      );
    }
    verticalDataValidationSchema[field.name] = validation;
  });

  const profileValidations =
    context === "create" ? createProfileValidations : updateProfileValidations;

  const profileValidationSchema = {
    ...profileValidations,
    verticalData: Yup.object().shape(verticalDataValidationSchema),
  };

  return Yup.object().shape({
    ...userFields,
    talentProfile: Yup.object().shape(profileValidationSchema),
  });
};

export interface TalentProfileFormValues {
  vertical: Maybe<TalentVertical>;
  gender: Maybe<Gender>;
  dateOfBirth: string;
  primaryLocation: TalentLocation;
  additionalLocations: TalentLocation[];
  biography: string;
  hobbies: string[];
  dietaryRequirements: Maybe<string[]>;
  pastClients: Maybe<string[]>;
  pronouns: Maybe<Pronouns>;
  instagramUsername: string;
  published?: boolean;
  agencyId: string;
  shrineAssets: Maybe<AssetFileUploadInput[]>;
  verticalData: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  minDayRate: Maybe<number>;
  maxDayRate: Maybe<number>;
  isContactOpen?: boolean;
  isContactSelect?: boolean;
  isContactLaunch?: boolean;
  website: string;
  portfolioImages: Maybe<PortfolioImageInput[]>;
  socialMediaFields: Maybe<any[]>;
}
export interface TalentFormValues {
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  postcode: string;
  talentProfile: TalentProfileFormValues;
}

export const initialUserValues = (
  verticalConfig: VerticalConfiguration | undefined,
  published: boolean,
): TalentFormValues => {
  const verticalData: Record<string, undefined> = {};

  if (verticalConfig) {
    verticalConfig.fields.forEach((field) => {
      verticalData[field.name] = undefined;
    });
  }

  return {
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    postcode: "",
    talentProfile: {
      vertical: TalentVertical.FashionModel,
      gender: undefined,
      dateOfBirth: "",
      primaryLocation: {
        name: "",
      } as TalentLocation,
      additionalLocations: [] as TalentLocation[],
      biography: "",
      hobbies: [],
      instagramUsername: "",
      published: published,
      agencyId: "",
      shrineAssets: [] as AssetFileUploadInput[],
      verticalData: verticalData,
      minDayRate: undefined,
      maxDayRate: undefined,
      pastClients: undefined,
      dietaryRequirements: undefined,
      pronouns: undefined,
      website: "",
      portfolioImages: [],
      socialMediaFields: [
        {
          platform: "",
          handle: "",
          followerCount: "",
        },
      ],
    },
  };
};
